import React from 'react';
import {View} from 'glamor/jsxstyle';
import clsx from 'clsx';
import usePagination, {DOTS} from '../../hooks/usePagination';
import {makeStyles} from '@material-ui/core';
import {Select, Option, Typography} from 'spenda-ui-react';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';

interface IPagination {
  onPageChange: (page: number) => void;
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
  rowsPerPageLabel?: string;
  rowsPerPageOptions?: number[];
  onRowsPerPageChange?: (rowsPerPage: number) => void;
}

const usePaginationStyles = makeStyles(() => ({
  paginationItem: {
    color: '#444343',
    margin: '0px 8px',
    fontSize: '1.15rem',
    display: 'flex',
    fontWeight: 500,
    '&:hover': {
      color: 'hsl(var(--primary))',
      opacity: '80%',
      cursor: 'pointer',
    },
  },
  icon: {
    color: 'hsl(var(--primary))',
    margin: '0px',
  },
  disabledClass: {
    color: 'hsl(var(--primary))',
    opacity: '30%',
    '&:hover': {
      color: 'hsl(var(--primary))',
      opacity: '30%',
      cursor: 'default',
    },
  },
  selectedClass: {
    color: 'hsl(var(--primary))',
    '&:hover': {
      opacity: '100%',
    },
  },
  dots: {
    color: '#CCCCCC',
    fontSize: '1.375rem',
    lineHeight: '',
    '&:hover': {
      color: '#CCCCCC',
      cursor: 'default',
      opacity: '100%',
    },
  },
}));

export const SPagination = (props: IPagination) => {
  const classes = usePaginationStyles();
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    rowsPerPageLabel = 'Rows per page:',
    rowsPerPageOptions,
    onRowsPerPageChange,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const handleRowsChange = (value?: string) => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(parseInt(value + '', 10));
    }
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="flex w-full flex-row items-center justify-end py-5">
      {rowsPerPageOptions && onRowsPerPageChange ? (
        <>
          <Typography className="mr-2 text-spenda-labeltext">{rowsPerPageLabel}</Typography>
          <Select
            value={`${pageSize}`}
            onChange={handleRowsChange}
            className="w-10 border-none"
            containerProps={{className: 'bg-spenda-footerBg min-w-[68px] w-16 mr-2 rounded-md'}}
            data-autoid="ddlRowsPerPage"
          >
            {rowsPerPageOptions.map(options => (
              <Option key={`rows-per-page-${options}`} value={`${options}`} style={{fontFamily: 'Poppins, sans-serif'}}>
                {options}
              </Option>
            ))}
          </Select>
        </>
      ) : null}
      <Typography className="mr-3 text-spenda-labeltext">
        Showing results{' '}
        {`${pageSize * currentPage - (pageSize - (paginationRange.length > 1 ? 1 : 1))}-${
          pageSize * currentPage > totalCount ? totalCount : pageSize * currentPage
        }`}
      </Typography>
      {paginationRange.length > 1 ? (
        <View display="flex" alignItems="center" listStyleType="none" component="ul">
          <li
            key="pagination-previous"
            className={clsx(classes.paginationItem, classes.icon, {
              [classes.disabledClass]: currentPage === 1,
            })}
          >
            <button
              type="button"
              onClick={() => currentPage !== 1 && onPrevious()}
              data-autoid="btnPreviousPage"
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftRoundedIcon className="text-primary" />
            </button>
          </li>
          {paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return (
                <li key={`pagination-dots-${index}`} className={clsx(classes.paginationItem, classes.dots)}>
                  &#8230;
                </li>
              );
            }

            return (
              <li
                key={`pagination-number-${pageNumber}`}
                className={clsx(classes.paginationItem, {
                  [classes.selectedClass]: pageNumber === currentPage,
                })}
              >
                <button
                  type="button"
                  onClick={() => onPageChange(parseInt(pageNumber + ''))}
                  data-autoid={`btnPage${pageNumber}`}
                  className="font-poppins text-lg"
                >
                  {pageNumber}
                </button>
              </li>
            );
          })}
          <li
            key="pagination-next"
            className={clsx(classes.paginationItem, classes.icon, {
              [classes.disabledClass]: currentPage === lastPage,
            })}
          >
            <button
              type="button"
              onClick={() => currentPage !== lastPage && onNext()}
              data-autoid="btnNextPage"
              disabled={currentPage === lastPage}
            >
              <KeyboardArrowRightRoundedIcon className="text-primary" />
            </button>
          </li>
        </View>
      ) : null}
    </div>
  );
};
