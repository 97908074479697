import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {
  Button,
  ButtonGroup,
  Popper,
  Grow,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Tooltip,
  withStyles,
  Theme,
} from '@material-ui/core';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import LoadingIndicator from '../../ui/LoadingIndicator';
import {ClaimsAndReturnsStatus} from '../../../model/constants/Constants';

interface IClaimsMultiMenuButtonOptions {
  disabled: boolean;
  disabledReason?: string;
  text: string | ReactElement;
  type: ClaimsAndReturnsStatus;
  color: '#1C78AD' | '#3C9F78';
}

export interface IClaimsMultiMenuButton {
  isSubmitting?: boolean;
  disabled?: boolean;
  claimsStatus: ClaimsAndReturnsStatus;
  handleClick: (action: ClaimsAndReturnsStatus) => void;
  disabledReason?: string;
  dataAutoIdSuffix?: string;
}

export const ClaimsMultiMenuButton = (props: IClaimsMultiMenuButton) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const {isSubmitting, claimsStatus, handleClick, dataAutoIdSuffix} = props;

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [options, setOptions] = useState<IClaimsMultiMenuButtonOptions[]>([]);

  const disabled = options[selectedIndex]?.disabled || props.disabled;
  const color = options[selectedIndex]?.color;

  useEffect(() => {
    // Check Permissions
    const _options: IClaimsMultiMenuButtonOptions[] = [];
    if (claimsStatus === ClaimsAndReturnsStatus.Sent) {
      _options.push({
        text: 'Approve',
        type: ClaimsAndReturnsStatus.Approved,
        disabled: false,
        color: '#1C78AD',
      });
      _options.push({
        text: 'Supplier Reviewing',
        type: ClaimsAndReturnsStatus.Reviewed,
        disabled: false,
        color: '#1C78AD',
      });
    }
    if (claimsStatus === ClaimsAndReturnsStatus.Reviewed) {
      _options.push({
        text: 'Approve',
        type: ClaimsAndReturnsStatus.Approved,
        disabled: false,
        color: '#1C78AD',
      });
      _options.push({
        text: 'Supplier Reviewing',
        type: ClaimsAndReturnsStatus.Reviewed,
        disabled: true,
        disabledReason: 'Review In Process',
        color: '#1C78AD',
      });
    }
    setOptions(_options);
  }, [claimsStatus]);

  useEffect(() => {
    // Set Options to first enabled
    const firstEnabled = options.findIndex(option => !option.disabled);
    if (firstEnabled > -1 && firstEnabled !== selectedIndex) {
      setSelectedIndex(firstEnabled);
    }
  }, [options]);

  const handleButtonClick = () => {
    handleClick(options[selectedIndex].type);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  if (!options.length) {
    return null;
  }

  const ARTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'poppins',
    },
  }))(Tooltip);

  return (
    <>
      <ButtonGroup ref={anchorRef} aria-label="split button">
        <ARTooltip
          title={options[selectedIndex]?.disabledReason || props.disabledReason || ''}
          disableHoverListener={!Boolean(disabled)}
        >
          <span>
            <Button
              data-autoid={`btnBatchAction${options[selectedIndex]?.type}${dataAutoIdSuffix || ''}`}
              style={{
                backgroundColor: disabled ? `rgb(244 244 244)` : color,
                border: `1px solid ${color}`,
                color: disabled ? `${color}` : '#FFF',
                cursor: disabled ? `not-allowed` : 'pointer',
              }}
              disabled={Boolean(disabled)}
              classes={{
                root: `!font-poppins !h-[40px] !py-[5px] !px-[8px] !text-[#FFF] !min-w-[150px] !font-medium !text-base !rounded-[6px] ${
                  options.length <= 1 ? `rounded-br-[6px] rounded-tr-[6px]` : `rounded-br-[0px] rounded-tr-[0px]`
                } normal-case`,
              }}
              onClick={handleButtonClick}
            >
              {options[selectedIndex]?.text}
              <LoadingIndicator isLoading={isSubmitting} size="sm" color="#FFF" position={{right: '5px'}} />
            </Button>
          </span>
        </ARTooltip>
        {options.length > 1 ? (
          <Button
            data-autoid="btnBatchActionDropdown"
            style={{border: `1px solid ${color}`}}
            size="small"
            classes={{
              root: '!bg-[#F5F5F5] !text-primary !rounded-[6px]',
              disabled: '!bg-[#F5F5F5] !text-primary',
            }}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            className={`${open ? '' : '!rotate-180'}`}
          >
            <KeyboardArrowDownRoundedIcon />
          </Button>
        ) : null}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{zIndex: 1, width: anchorRef?.current?.clientWidth}}
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom',
            }}
          >
            <div className={`min-w-[150px] rounded-[4px] border border-[#EAEAEA] bg-[#FFF] shadow-md`}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-selectTimelineRange" data-autoid={`BatchAction_DropDown_lst`}>
                  {options.map((option, index) => (
                    <MenuItem
                      data-autoid={`BatchAction_DropDown${option.type}_mnu`}
                      classes={{
                        root: 'text-[#444343] font-poppins text-[0.8rem] py-[6px] px-2.5 hover:bg-[#EAEFF260]',
                        selected: '!bg-[#EAEFF2]',
                      }}
                      disableGutters
                      key={option.type}
                      selected={index === selectedIndex}
                      onClick={event => (option?.disabled ? undefined : handleMenuItemClick(event, index))}
                      disabled={option?.disabled}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
};
