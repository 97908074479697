import React, {useContext} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';
import {useAuth} from '../../../services/useAuth';
import AppContext from '../../../context/app/appContext';
import {isProductionEnv} from '../../../config';

interface IBookOnboardingModalProps {
  isOpen: boolean;
}

export const BookOnboardingModal = (props: IBookOnboardingModalProps) => {
  // Props
  const {isOpen} = props;

  // Hooks
  const appContext = useContext(AppContext);
  const {logout} = useAuth();

  const handleBookNowClick = () => {
    const bookingURL = `https://outlook.office365.com/book/StatementIntegrationBookingForm@theappstablishment.onmicrosoft.com/`;
    window.open(isProductionEnv ? bookingURL : 'https://spenda.co', '_blank');
    logout().finally(() => {
      appContext.logout();
    });
  };

  return (
    <ARDialogTemplate
      isFullScreen
      dialogProps={{
        size: 'xs',
        open: isOpen,
        className: '!min-w-[490px] !max-w-[490px] ',
      }}
      header={<p className="font-poppins text-xl text-[#333333]">Book Onboarding</p>}
      body={
        <div className="m-10">
          <Typography variant="paragraph" className="text-center text-lg text-black-800">
            To finalise your onboarding, please book a time with one of our implementation specialists to complete your
            setup.
          </Typography>
        </div>
      }
      footer={
        <div className="flex w-full  items-center justify-center">
          <Button data-autoid={`btnBookNow`} variant="filled" color="primary" onClick={handleBookNowClick}>
            Book now
          </Button>
        </div>
      }
    />
  );
};
