import React from 'react';
import {Header} from './Header';
import {css} from 'glamor';
import {Transition} from 'react-transition-group';
import {RouteProps} from 'react-router-dom';

export interface ILayoutProps {
  leftPanel?: any;
  mainPanel?: any;
  rightPanel?: any;
  modal?: any;
  toggleView?: boolean;
  splitWidthType?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 'AR' | 'Quote' | 'MP';
  duration?: number;
}
export const Layout = ({duration = 500, ...props}: ILayoutProps & RouteProps) => {
  let leftPanelWidth = '50%';
  let rightPanelWidth = '50%';

  switch (props.splitWidthType) {
    case 1:
      leftPanelWidth = '50%';
      rightPanelWidth = '50%';
      break;
    case 2:
      leftPanelWidth = '45%';
      rightPanelWidth = '55%';
      break;
    case 3:
      leftPanelWidth = '40%';
      rightPanelWidth = '60%';
      break;
    case 4:
      leftPanelWidth = '100%';
      rightPanelWidth = '0';
      break;
    case 5:
      leftPanelWidth = '68%';
      rightPanelWidth = '31%';
      break;
    case 6:
      leftPanelWidth = '17%';
      rightPanelWidth = '83%';
      break;
    case 7:
      leftPanelWidth = '0';
      rightPanelWidth = '100%';
      break;
    case 'AR':
      leftPanelWidth = 'calc(100% - 426px)';
      rightPanelWidth = '414px';
      break;
    case 'Quote':
      leftPanelWidth = '314px';
      rightPanelWidth = 'calc(100% - 314px)';
      break;
    case 'MP':
      leftPanelWidth = 'calc(100% - 319px)';
      rightPanelWidth = '307px';
      break;
  }
  const grid = css({
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: '100px auto',
    gridTemplateAreas: `"header" "main"`,
  });

  const leftPanelBaseStyles = css({
    boxSizing: 'border-box',
    display: 'inline-block',
    height: '100%',
    top: '0',
    position: 'relative',
    width: leftPanelWidth,
    left: '0',
    transition: `left ${duration}ms`,
    '@media(max-width: 1280px)': {
      height: '100%',
    },
  });

  const leftPanelTransitionStyles = {
    entering: {left: `-${leftPanelWidth}`},
    entered: {left: `-${leftPanelWidth}`},
    exiting: {left: '0'},
    exited: {left: '0'},
    unmounted: undefined,
  };

  const mainPanelBaseStyles = css({
    boxSizing: 'border-box',
    display: 'inline-block',
    top: '0',
    height: '84%',
    position: 'absolute',
    zIndex: 0, //For Chatbox to appear in the front with no overlapping
    width: rightPanelWidth,
    transition: `left ${duration}ms`,
    minHeight: '100%',
    '@media(max-width: 1280px)': {
      width: props.toggleView ? rightPanelWidth : rightPanelWidth,
      height: '100%',
    },
  });

  const mainPanelTransitionStyles = {
    entering: {left: '0'},
    entered: {left: '0'},
    exiting: {left: leftPanelWidth},
    exited: {left: leftPanelWidth},
    unmounted: undefined,
  };

  const rightPanelBaseStyle = css({
    transition: `right ${duration}ms ease-in-out`,
    right: `-${rightPanelWidth}`,
    boxSizing: 'border-box',
    display: 'inline-block',
    height: '100%',
    position: 'absolute',
    top: '0',
    width: rightPanelWidth,
    '@media(max-width: 1280px)': {
      width: rightPanelWidth,
    },
  });

  const rightPanelTransitionStyles = {
    entering: {right: '0'},
    entered: {right: '0'},
    exiting: {right: `-${rightPanelWidth}`},
    exited: {right: `-${rightPanelWidth}`},
    unmounted: undefined,
  };

  return (
    <div className={`h-full ${grid}`}>
      <Header></Header>

      <main className={`relative h-full overflow-hidden`}>
        <Transition in={props.toggleView} timeout={duration}>
          {state => (
            <section
              id="left-panel"
              className={`flex flex-col p-2.5 ${leftPanelBaseStyles}`}
              style={{...leftPanelTransitionStyles[state]}}
            >
              {props.leftPanel}
            </section>
          )}
        </Transition>
        <Transition in={props.toggleView} timeout={duration}>
          {state => (
            <section
              id="main-panel"
              className={`pt-2.5 ${mainPanelBaseStyles}`}
              style={{...mainPanelTransitionStyles[state]}}
            >
              {props.mainPanel}
            </section>
          )}
        </Transition>
        <Transition in={props.toggleView} timeout={duration}>
          {state => (
            <section
              id="right-panel"
              className={`p-2.5 pl-0 ${rightPanelBaseStyle}`}
              style={{...rightPanelTransitionStyles[state]}}
            >
              {props.rightPanel}
            </section>
          )}
        </Transition>
        {props.modal}
      </main>
    </div>
  );
};
