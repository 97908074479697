import React, {useEffect, useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Select, Typography, Option} from 'spenda-ui-react';
import {ARTable} from '../ARTable';
import clsx from 'clsx';
import {ISelectedRow} from '../../../screens/AccountsReceivable/requires-attention/RequiresAttention';
import {DatTypes} from '../../../model/constants/Constants';
import {PriceFormat} from '../../../utils/formatter';
import {useInventoryClassAPI} from '../../../services/useInventoryClassesAPI';
import {InventoryClass} from '../../../model/inventory/InventoryClass';

interface IVerifyingTaxAmountDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRows: ISelectedRow[];
  handleDone: (updatedSelectedRows: ISelectedRow[]) => void;
}

export const VerifyingTaxAmountDialog = (props: IVerifyingTaxAmountDialogProps) => {
  // Props
  const {isOpen, onClose, selectedRows, handleDone} = props;

  //API
  const {dashboard} = useInventoryClassAPI();

  // State
  const [updatedSelectedRows, setUpdatedSelectedRows] = useState<ISelectedRow[]>([]);
  const [inventories, setInventories] = useState<InventoryClass[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getInventory();
  }, []);

  useEffect(() => {
    if (inventories.length > 0 && selectedRows.length > 0) {
      const updatedRows = selectedRows.map(row => {
        const selectedInventory = inventories.find(inv => inv.InventoryCode === row.InventoryCode);
        return {
          ...row,
          InventoryID: selectedInventory ? selectedInventory.ID : row.InventoryID || undefined,
          InventoryCode: selectedInventory ? selectedInventory.InventoryCode : row.InventoryCode || '',
        };
      });
      setUpdatedSelectedRows(updatedRows);
    }
  }, [inventories, selectedRows]);

  const getInventory = async () => {
    setIsLoading(true);
    try {
      const response = await dashboard({
        DatTypeID: DatTypes.Inventory,
        IsPurchased: true,
        IsPostingInventoryItem: true,
        MaxResults: 1000,
      });
      const filteredInventory = response?.Value?.filter(item => item?.IsPurchased === true);
      setInventories(filteredInventory || []);
    } catch (error) {
      console.error('Error fetching inventory:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const {value} = e.target;

    setUpdatedSelectedRows(prev => {
      const updatedRows = [...prev];
      if (/^\d*(\.\d{0,2})?$/.test(value) && Number(value) >= 0 && Number(value) <= selectedRows?.[index]?.totalInc) {
        updatedRows[index].totalTax = parseFloat(value);
      }
      return updatedRows;
    });
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
    const {value} = e.target;
    if (value === '' || value === undefined) {
      setUpdatedSelectedRows(prev => {
        const updatedRows = [...prev];
        updatedRows[index].totalTax = 0;
        return updatedRows;
      });
    }
  };

  const handleInventoryChange = (inventoryID: string, inventoryCode: string, index: number) => {
    setUpdatedSelectedRows(prev => {
      const updatedRows = prev.map((row, i) => {
        if (i === index) {
          return {
            ...row,
            InventoryID: Number(inventoryID),
            InventoryCode: inventoryCode,
          };
        }
        return row;
      });
      return updatedRows;
    });
  };

  const columns = [
    {
      title: 'Ref ID',
      key: 'refID',
      width: '20%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ISelectedRow) => {
        return (
          <Typography
            data-autoid={`lblRefID`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium text-black-800"
          >
            {rowData?.documentID}
          </Typography>
        );
      },
    },
    {
      title: 'Suppliers',
      key: 'suppliers',
      width: '40%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ISelectedRow) => {
        return (
          <Typography
            data-autoid={`lblSuppliers`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium text-black-800"
          >
            {rowData?.supplier}
          </Typography>
        );
      },
    },
    {
      title: 'Inventory',
      key: 'inventory',
      width: '20%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-0',
      isSortable: false,
      rowRenderer: (rowData: ISelectedRow, index: number) => {
        const inventoryCode = updatedSelectedRows[index]?.InventoryCode;
        return (
          <>
            <Select
              containerProps={{className: '!border-none relative overflow-visible'}}
              className="relative h-full !rounded-[3px] border-[1px] border-solid border-[#777] bg-white text-center !outline-none disabled:!bg-transparent disabled:text-[#33333350]"
              variant="outlined"
              value={inventoryCode}
              selected={() => inventoryCode}
              onChange={value => {
                const selectedOption = inventories.find(itm => itm?.ID.toString() === value);
                if (selectedOption?.ID && selectedOption?.InventoryCode) {
                  handleInventoryChange(selectedOption.ID, selectedOption?.InventoryCode, index);
                }
              }}
            >
              {inventories?.map(itm => (
                <Option key={itm?.ID} value={itm?.ID.toString()} className="">
                  {itm?.InventoryCode}
                </Option>
              ))}
            </Select>
          </>
        );
      },
    },

    {
      title: 'Invoice total',
      key: 'invoiceTotal',
      width: '20%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ISelectedRow) => {
        return (
          <Typography
            data-autoid={`lblInvoiceTotal`}
            variant="paragraph"
            className="inline min-w-[100px] overflow-ellipsis font-medium text-black-800"
          >
            {PriceFormat(rowData?.totalInc)}
          </Typography>
        );
      },
    },
    {
      title: 'Tax amount',
      key: 'taxAmount',
      width: '20%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-0',
      isSortable: false,
      rowRenderer: (rowData: ISelectedRow, index: number) => {
        return (
          <>
            {rowData?.datTypeID === DatTypes.StagedDebitNote ? (
              <Typography
                data-autoid={`lblTaxAmount`}
                variant="paragraph"
                className="inline overflow-ellipsis p-2.5 font-medium text-black-800"
              >
                {PriceFormat(rowData?.totalTax)}
              </Typography>
            ) : (
              <div className="relative flex w-full min-w-[100px] flex-row">
                <span className="absolute left-1 top-2.5 text-base font-semibold text-black-800">$</span>
                <input
                  type="number"
                  data-autoid={`txtTaxAmount`}
                  className={clsx(
                    'm-0 h-full w-full !rounded-[3px] border-[1px] border-solid border-[#777] !p-2.5 !pl-3 text-right font-poppins text-base font-semibold leading-normal text-black-800 placeholder:text-base placeholder:text-[#ccc]',
                  )}
                  value={updatedSelectedRows[index]?.totalTax}
                  name={rowData?.documentID?.toString()}
                  onChange={e => handleChange(e, index)}
                  onBlur={e => handleBlur(e, index)}
                  onKeyDown={e => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                      e.preventDefault();
                    }
                  }}
                  onWheel={e => e.currentTarget.blur()}
                />
              </div>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <ARDialogTemplate
        dialogProps={{
          size: 'lg',
          open: isOpen,
          className: '2xl:!max-w-[793px] 2xl:!min-w-[793px] 2xl:!w-[793px] xl:!max-w-[65%] xl:!min-w-[65%] xl:!w-[65%]',
        }}
        isFullScreen
        header={
          <p data-autoid={'lblVerifyTaxAmountTitle'} className=" font-poppins text-xl font-light text-[#333333]">
            Verifying invoice details
          </p>
        }
        body={
          <div className="flex h-[inherit] max-h-[548px] w-full flex-col">
            <Typography className="my-5 text-center font-normal text-black-800" variant="paragraph">
              Please ensure the tax amount displayed on the transaction from [statement provider] invoice is consistent
              with the tax shown on your invoice, and that the invoice is posting to the correct inventory account.
              Adjust the options below before clicking ‘Continue’.
            </Typography>
            <div className={`relative ${isLoading ? 'py-4' : ''}`}>
              <ARTable
                isHighlightRowOnHover
                conatinerClass={`${updatedSelectedRows.length <= 8 ? 'overflow-y-visible' : 'overflow-y-auto'}`}
                isLoading={isLoading}
                columns={columns}
                rows={updatedSelectedRows}
                getRowDataAutoId={rowData => `rowVerifyTaxAmount${rowData?.documentID}`}
              />
            </div>
          </div>
        }
        footer={
          <div className="flex w-full flex-row items-center justify-between">
            <Button data-autoid={`btnCancel`} variant="outlined" color="error" className="bg-white" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="filled"
              color="primary"
              data-autoid={`btnContinue`}
              onClick={() => handleDone(updatedSelectedRows)}
            >
              Continue
            </Button>
          </div>
        }
      />
    </>
  );
};
