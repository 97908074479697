import React, {useEffect, useState, useContext} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Formik, Form, FormikProps} from 'formik';
import {Button} from 'spenda-ui-react';
import * as Yup from 'yup';

import AppContext from '../../context/app/appContext';
import {AUTH_SELLING_SALES_ORDER_LIST, AUTH_SELLING_SALES_ORDER_PICK} from '../../routes/SalesOrderRoutes';
import {DISCOUNT_MODE, SALES_ORDER_STATUS, DatTypes} from '../../model/constants/Constants';
import {ICustomer} from '../../model/customer/Customer';
import {ISalesOrder, ISalesOrderLine, SalesOrderReservationStatus} from '../../model/sales-order/SalesOrder';
import {Layout} from '../../components/layout/Layout';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {SalesOrderCustomerForm} from '../../components/form/SalesOrderCustomerForm';
import {SalesOrderDetailLines} from '../../components/sales-orders/SalesOrderDetailLines';
import {useSalesOrderAPI} from '../../services/useSalesOrderAPI';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {CreateSalesOrderValues} from '../../components/form/SalesOrderForm';
import {Toast} from '../../utils/Toast';
import {SalesOrderSideBar} from '../../components/sales-orders/SalesOrderSideBar';
import {AlertDialog} from '../../components/dialog/AlertDialogSlideV2';
import {VisibleContent} from '../../components/ui/VisibleContent';

const validationSchema = Yup.object({
  CustomerRefTransRefNumber: Yup.string().max(50, 'Customer Ref max 50 char').nullable(true),
  ContactPhone: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Phone number is not valid',
    )
    .required('A phone number is required'),
  ContactEmailAddress: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  CustomerName: Yup.string().nullable(true).required('Customer Name is required'),
  ShippingAddress: Yup.object()
    .shape({
      Country: Yup.string().required(),
    })
    .nullable()
    .required('Shipping address is required.'),
  BillingAddress: Yup.object()
    .shape({
      Country: Yup.string().required(),
    })
    .nullable()
    .required('Billing address is required.'),
  DiscountMode: Yup.string(),
  Discount: Yup.number().when(['DiscountMode'], (DiscountMode: string, DiscountSchema: Yup.NumberSchema) => {
    switch (DiscountMode) {
      case DISCOUNT_MODE.PERCENT:
        return DiscountSchema.typeError('Enter a number')
          .min(1, 'Enter a discount percentage of at least 1%')
          .max(100, 'Enter a discount percentage of at most 100%');
      case DISCOUNT_MODE.DOLLAR:
        return DiscountSchema.typeError('Enter a number').min(0, 'Enter a discount amount of at least 0');
      default:
        return DiscountSchema;
    }
  }),
  Lines: Yup.array()
    .of(
      Yup.object({
        ShortDescription: Yup.string().nullable(true),
        SellPriceExString: Yup.string()
          .nullable(true)
          .when(['ShortDescription'], (ShortDescription: string, schema: any) => {
            return ShortDescription ? schema.required(' Price is required') : schema.nullable();
          }),
        Quantity: Yup.number()
          .nullable(true)
          .when(['ShortDescription'], (ShortDescription: string, schema: any) => {
            return ShortDescription ? schema.required('Quantity is required') : schema.nullable();
          }),
      }),
    )
    .min(1),
});

export const SalesOrderDetails = () => {
  const history = useHistory();
  let {orderID} = useParams<{orderID?: string | undefined}>();
  const {tenantInfo} = useContext(AppContext);
  const [orderDetails, setOrderDetails] = useState<any>({});
  const [customerDetails, setCustomerDetails] = useState<ICustomer>();
  const [accountDetails, setAccountDetails] = useState<any>();
  const [customerInvoices, setCustomerInvoices] = useState<any>();
  const [editMode, setEditMode] = useState<boolean>(false);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [showCancelOrder, setShowCancelOrder] = useState<boolean>(false);

  const {
    getOrderById,
    updateSalesOrder,
    updateSalesOrderStatus,
    deleteSalesOrderById,
    invoiceSalesOrder,
    isLoading: isLoadingOrder,
  } = useSalesOrderAPI();
  const {getCustomer, getcustomerStatements, isLoading: isLoadingCustomer} = useCustomersAPI();

  const isLoading = isLoadingOrder || isLoadingCustomer;

  useEffect(() => {
    if (orderID) {
      getOrder();
    }
  }, [orderID]);

  const getOrder = async () => {
    getOrderById(orderID).then(async (order: ISalesOrder) => {
      const customer = await getCustomer(order?.CustomerID);
      if (customer.AccountCustomerID) {
        const accountDetailsRes = await getCustomer(customer.AccountCustomerID);
        const customerStatementsRes = await getcustomerStatements(customer?.AccountCustomerID);
        setAccountDetails(accountDetailsRes);
        setCustomerInvoices(customerStatementsRes);
      }

      let _freight = 0;
      const freightLineItemIndex = order.Lines.findIndex(
        _line => _line.InventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId,
      );
      if (freightLineItemIndex > -1) {
        _freight = order.Lines[freightLineItemIndex]?.LineTotalEx || 0;
      }

      const orderResp = {
        ...order,
        Reserve: {
          status: 'Reserved',
        },
        FreightTotalEx: _freight,
        BillingAddress: {
          City: order?.BillCity,
          Country: order?.BillCountry,
          StreetAddress: order?.BillStreetAddress,
          PostCode: order?.BillPostCode,
          State: order?.BillState,
        },
        BillingAddressID: order.BillingAddressID,
        ShippingAddress: {
          City: order?.ShipCity,
          Country: order?.ShipCountry,
          StreetAddress: order?.ShipStreetAddress,
          PostCode: order?.ShipPostCode,
          State: order?.ShipState,
        },
        ShippingAddressID: order.ShippingAddressID,
      };

      setEditMode(false);
      setCustomerDetails(customer);
      setOrderDetails(orderResp);
    });
  };

  const onSubmit = (): void => {};

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent && keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const handleSetAddress = async (addressID: string, addressString: string, addressType: string) => {
    setOrderDetails({
      ...orderDetails,
      ...(addressType === 'ShippingAddress'
        ? {
            ShippingAddress: addressString,
            ShippingAddressID: addressID,
          }
        : {}),
      ...(addressType === 'BillingAddress'
        ? {
            BillingAddress: addressString,
            BillingAddressID: addressID,
          }
        : {}),
    });
  };

  const handleCreateInvoice = async () => {
    setEditMode(false);
    invoiceSalesOrder(orderID).then(async res => {
      if (res.IsSuccess) {
        Toast.info('Sales Order Invoice Created');
        getOrder();
      }
    });
  };

  const handleApproveOrder = async (
    fProps: FormikProps<CreateSalesOrderValues>,
    redirectToListing: boolean = false,
  ) => {
    setEditMode(false);
    updateSalesOrderStatus(orderID, SALES_ORDER_STATUS.APPROVED).then(async res => {
      if (res.IsSuccess) {
        const pickSlip = res.CreatedObjects.find(obj => obj.DatType === DatTypes.PickSlip);
        Toast.info('Sales Order Approved');
        if (pickSlip && !redirectToListing) {
          history.push(AUTH_SELLING_SALES_ORDER_PICK.replace(/:pickSlipID/g, pickSlip.ID + ''));
        } else {
          history.push(AUTH_SELLING_SALES_ORDER_LIST);
        }
      }
    });
  };

  const handleDeleteOrder = async () => {
    setEditMode(false);
    deleteSalesOrderById(orderID).then(async res => {
      if (res.IsSuccess) {
        Toast.info('Sales Order Deleted');
        setOpenDeleteDialog(false);
        history.push(AUTH_SELLING_SALES_ORDER_LIST);
      }
    });
  };

  const updateOrderLines = async (fProps: FormikProps<CreateSalesOrderValues>, redirectToList = false) => {
    const {values} = fProps;
    if (values.DiscountMode === DISCOUNT_MODE.PERCENT && values.Discount && (values.Discount as number) > 1) {
      values.Discount = Number(values.Discount) / 100;
    }
    const lines = values.Lines.filter((_line: ISalesOrderLine) => _line.Code);
    // Check for deleted line items
    if (Array.isArray(orderDetails.Lines)) {
      orderDetails.Lines.map((line: Partial<ISalesOrderLine>) => {
        if (!lines.some((l: ISalesOrderLine) => l.ID === line.ID)) {
          lines.push({
            ID: line.ID,
            InventoryID: line.InventoryID,
            IsActive: false,
          });
        }
      });
    }
    const payload = {
      Lines: lines,
      Discount: values.Discount || 0,
      FreightTotalEx: values.FreightTotalEx || 0,
      DiscountMode: values.DiscountMode,
      CustomerRefTransRefNumber: '',
    };
    if (values.CustomerRefTransRefNumber) {
      payload.CustomerRefTransRefNumber = values.CustomerRefTransRefNumber;
    }
    await updateSalesOrder(orderID, {...payload});
    Toast.info('Sales Order updated');
    if (redirectToList) {
      history.push(AUTH_SELLING_SALES_ORDER_LIST);
    } else {
      getOrder();
    }
  };

  const handleCloseClick = (props: FormikProps<CreateSalesOrderValues>) => {
    if (props.dirty) {
      setOpenConfirmationDialog(true);
    } else {
      history.push(AUTH_SELLING_SALES_ORDER_LIST);
    }
  };

  const handleCancelSalesOrder = async () => {
    setShowCancelOrder(false);
    updateSalesOrderStatus(orderID, SALES_ORDER_STATUS.CANCELLED).then(async res => {
      if (res.IsSuccess) {
        Toast.info('Sales Order Cancelled');
        history.push(AUTH_SELLING_SALES_ORDER_LIST);
      }
    });
  };

  const _orderDetails = (
    <Formik enableReinitialize validationSchema={validationSchema} initialValues={orderDetails} onSubmit={onSubmit}>
      {props => (
        <Form onKeyDown={onKeyDown} onSubmit={props.handleSubmit} className="flex h-full flex-col">
          {
            <div className="relative h-full overflow-y-auto">
              <div className="flex h-full gap-[9px]">
                <div className="relative flex flex-1 flex-col">
                  <SalesOrderCustomerForm
                    {...props}
                    createSalesOrder={false}
                    selectedCustomer={customerDetails}
                    accountDetails={accountDetails}
                    customerInvoices={customerInvoices}
                    setSelectedCustomer={setCustomerDetails}
                  />
                  <SalesOrderDetailLines
                    {...props}
                    lineItemsError={false}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    customerDetails={customerDetails}
                    handleSetAddress={handleSetAddress}
                  />
                  {props.values.Status === SALES_ORDER_STATUS.OPEN ? (
                    <div className="fixed bottom-4 w-[calc(100%-328px)] px-3">
                      <div className="flex w-full flex-row justify-between rounded-lg bg-spenda-footerBg p-2">
                        <div>
                          <Button variant="outlined" className="bg-white" onClick={() => handleCloseClick(props)}>
                            Cancel
                          </Button>
                          {props.values?.Reservation?.Status === SalesOrderReservationStatus.Active && (
                            <Button
                              variant="outlined"
                              onClick={() => setShowCancelOrder(true)}
                              className="ml-1 bg-white"
                            >
                              Cancel Order
                            </Button>
                          )}
                        </div>

                        <VisibleContent keyPath="salesOrders.customerOrderDetails.deleteOrderButton">
                          {props.values?.Reservation?.Status !== SalesOrderReservationStatus.Active && (
                            <Button color="error" onClick={() => setOpenDeleteDialog(true)}>
                              Delete this Order
                            </Button>
                          )}
                          <div>
                            <Button
                              loading={props.isSubmitting}
                              disabled={props.isSubmitting || !props.dirty || !props.isValid}
                              onClick={() => updateOrderLines(props)}
                            >
                              Save
                            </Button>
                            <Button
                              className="ml-1"
                              disabled={props.isSubmitting || !props.dirty || !props.isValid}
                              onClick={() => updateOrderLines(props, true)}
                              loading={props.isSubmitting}
                            >
                              Save & Close
                            </Button>
                          </div>
                        </VisibleContent>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="flex h-full w-[303px] flex-col">
                  <SalesOrderSideBar
                    isLoading={isLoading}
                    handleApproveOrder={handleApproveOrder}
                    handleCreateInvoice={handleCreateInvoice}
                    {...props}
                  />
                </div>
              </div>
            </div>
          }
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialog
          title="Discard Changes"
          headingTextSize="h2"
          contextTextVariant="paragraph"
          content="You have unsaved changes, Would you like to discard them or return to the previous screen?"
          actions={[
            {
              label: 'Cancel',
              action: () => setOpenConfirmationDialog(false),
              variant: 'outlined',
            },
            {
              label: 'Discard',
              action: () => history.push(AUTH_SELLING_SALES_ORDER_LIST),
            },
          ]}
        />
      )}
      {showCancelOrder && (
        <AlertDialog
          headingTextSize="h2"
          title="Cancelling order"
          contextTextVariant="small"
          contentClass="px-24 py-12"
          data-autoid="dlgCancelOrder"
          size="sm"
          content="Are you sure you would like to cancel this order? The order will not be completed and the customer will not be charged."
          actions={[
            {
              label: 'Cancel',
              action: () => setShowCancelOrder(false),
              variant: 'outlined',
            },
            {
              label: 'Cancel Order',
              action: handleCancelSalesOrder,
            },
          ]}
        />
      )}
      {openDeleteDialog && (
        <AlertDialog
          title="Delete Sales Order"
          headingTextSize="h2"
          contextTextVariant="paragraph"
          data-autoid="dlgDeleteOrder"
          size="sm"
          content="Are you sure you want to delete this order ?"
          actions={[
            {
              label: 'Cancel',
              action: () => setOpenDeleteDialog(false),
              variant: 'outlined',
            },
            {
              label: 'Delete',
              color: 'error',
              action: handleDeleteOrder,
            },
          ]}
        />
      )}
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout leftPanel={_orderDetails} splitWidthType={4} />
        <LoadingIndicator isLoading={isLoading} size="md" />
      </div>
    </>
  );
};
