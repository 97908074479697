import React, {FunctionComponent} from 'react';
import {css} from 'glamor';
import {Checkbox, CheckboxProps, FormControlLabel, makeStyles, withStyles} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IconCheckboxUnchecked from '../../assets/svg/IconCheckboxUnchecked';
import IconCheckboxChecked from '../../assets/svg/IconCheckboxChecked';
import {createAutoIdAttributeFromName} from '../../utils/createAutoIdAttribute';

export interface ICheckboxProps extends CheckboxProps {
  label: string;
  textColor?: string;
  fontFamily?: string;
  lableFontSize?: string;
  lableFontWeight?: number;
  checkboxFontSize?: string;
  labelAlign?: string;
}

const StyledCheckbox = withStyles({
  root: {
    color: '#1C78AD',
    '&$checked': {
      color: '#1C78AD',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checked: {},
})(Checkbox);

export const SCheckbox: FunctionComponent<ICheckboxProps> = props => {
  const {
    disabled,
    label,
    textColor = '#333',
    fontFamily = 'Saira Condensed',
    inputProps,
    lableFontSize = '1rem',
    lableFontWeight = 400,
    checkboxFontSize = '1.25rem',
    labelAlign,
    ...checkboxProps
  } = props;

  const defaultStyles = {
    labelRoot: {
      color: `${textColor} !important` || '#999999 !important',
      fontSize: `${lableFontSize} !important`,
      '& .MuiTypography-root': {
        fontFamily: fontFamily,
        fontWeight: `${lableFontWeight} !important`,
        fontSize: `${lableFontSize} !important`,
        textAlign: labelAlign,
      },
    },
    checkboxRoot: {
      color: disabled ? '#1C78AD50 !important' : 'hsl(var(--primary)) !important',
    },
  };

  const useCheckboxStyles = makeStyles(defaultStyles);
  const classes = useCheckboxStyles();

  return (
    <FormControlLabel
      disabled={Boolean(disabled)}
      classes={{root: classes.labelRoot, disabled: classes.labelRoot}}
      control={
        <StyledCheckbox
          disableRipple
          classes={{root: classes.checkboxRoot}}
          icon={<CheckBoxOutlineBlankIcon {...css({fontSize: `${checkboxFontSize} !important`})} />}
          checkedIcon={<CheckBoxIcon {...css({fontSize: `${checkboxFontSize} !important`})} />}
          {...checkboxProps}
          inputProps={{
            ...createAutoIdAttributeFromName(label.length > 0 ? label : checkboxProps?.name, 'chk'),
            ...(inputProps || {}),
          }}
        />
      }
      label={label}
    />
  );
};

export const StyledCustomCheckbox = withStyles(() => ({
  root: {
    padding: '0px',
  },
}))((props: CheckboxProps) => (
  <Checkbox {...props} icon={<IconCheckboxUnchecked />} checkedIcon={<IconCheckboxChecked />} />
));
