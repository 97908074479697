import React, {FunctionComponent, useState} from 'react';
import {omit} from 'lodash';
import {Button, Checkbox, Typography} from 'spenda-ui-react';
import {InventoryClass} from '../../model/inventory/InventoryClass';
import {IWarehouse} from '../../model/Warehouse';
import {ICategory} from '../../model/inventory/Category';
import {IBrand} from '../../model/inventory/Brand';
import {ISupplier} from '../../model/supplier/Supplier';
import {STableToolbar} from '../tables/STableToolbar';
import {
  CategoryAutocomplete,
  BrandAutocomplete,
  InventoryClassAutocomplete,
  WarehouseAutocomplete,
  SupplierAutocomplete,
} from '../inventory/InventoryAutocomplete';
import {SSearchInput} from '../inputs/SSearchInput';
import {FilterMenu, FilterMenuItem} from '../menu/FilterMenu';
import {ModifyDialog} from '../dialog/ModifyInventoryDialog';
import {AlertDialogSlide} from '../dialog/AlertDialogSlide';
import useBrandAPI from '../../services/useBrandAPI';
import IconFilter from '../../assets/svg/IconFilter';
import IconSearch from '../../assets/svg/IconSearch';
import IconKeyboardArrowDownRounded from '../../assets/svg/IconKeyboardArrowDownRounded';
import {CloseCross} from '../../assets/svg/CloseCross';
import IconColumnSetting from '../../assets/svg/IconColumnSetting';
import {Collapse, IconButton, Popover, Radio, RadioGroup, FormControlLabel} from '@material-ui/core';
import {usePopupState, bindTrigger, bindMenu, bindPopover} from 'material-ui-popup-state/hooks';

export interface IInventoryToolbar {
  title?: string;
  loading?: boolean;
  searchFilter: any;
  columns?: any;
  selectedColumns?: any;
  setSelectedColumns?: any;
  selected?: number[];
  data?: any[];
  refetchList?: () => void;
  setSelected?: (selected: number[]) => void;
  setSearchFilter: (filter: any) => void;
}

export const InventoryToolbar: FunctionComponent<Partial<IInventoryToolbar>> = props => {
  const [open, setOpen] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [physical, setPhysical] = useState<string>('');
  const [productPublished, setProductPublished] = useState<string>('');
  const [productPosting, setProductPosting] = useState<string>('');
  const [categoryFilter, setCategoryFilter] = useState<Partial<ICategory>>();
  const [brandFilter, setBrandFilter] = useState<Partial<IBrand>>();
  const [classFilter, setClassFilter] = useState<Partial<InventoryClass>>();
  const [supplierFilter, setSupplierFilter] = useState<Partial<ISupplier>>();
  const [warehouseFilter, setWarehouseFilter] = useState<Partial<IWarehouse>>();
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const {
    title,
    loading,
    searchFilter,
    selectedColumns,
    columns,
    setSelectedColumns,
    data,
    selected,
    refetchList,
    setSelected,
    setSearchFilter,
  } = props;

  const categoryPopupState = usePopupState({variant: 'popover', popupId: 'categoryPopupState'});
  const brandPopupState = usePopupState({variant: 'popover', popupId: 'brandPopupState'});
  const classPopupState = usePopupState({variant: 'popover', popupId: 'classPopupState'});
  const warehousePopupState = usePopupState({variant: 'popover', popupId: 'warehousePopupState'});
  const supplierPopupState = usePopupState({variant: 'popover', popupId: 'supplierPopupState'});
  const productTypePopupState = usePopupState({variant: 'popover', popupId: 'productTypePopupState'});
  const physicalPopupState = usePopupState({variant: 'popover', popupId: 'physicalPopupState'});
  const columnSettingPopupState = usePopupState({variant: 'popover', popupId: 'columnSettingPopupState'});

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleSearchInput = (newValue: string) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber', 'Search']),
        ...(newValue ? {Search: newValue} : {}),
      });
    }
  };

  const handleColumnCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelected = [...selectedColumns];
    if (event.target.checked) {
      currentSelected.push(event.target.name);
    } else {
      const index = currentSelected.indexOf(event.target.name);
      if (index !== -1) {
        currentSelected.splice(index, 1);
      }
    }
    setSelectedColumns(currentSelected);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(data) && Array.isArray(selected) && typeof setSelected === 'function') {
      const selectedSet = new Set(selected);

      for (let _data of data) {
        if (event.target.checked) {
          selectedSet.add(_data.ID);
        } else {
          selectedSet.delete(_data.ID);
        }
      }
      setSelected(Array.from(selectedSet));
    }
  };

  const handleArchiveClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSearchFilter) {
      setShowArchive(event.target.checked);
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber']),
        ...{IsShowActiveOnly: !event.target.checked},
      });
    }
    setSelected && setSelected([]);
  };

  const handlePhysicalFilter = (value: string) => {
    if (setSearchFilter) {
      setPhysical(value);
      setSearchFilter({
        ...searchFilter,
        ...{'Types[]': value},
      });
      physicalPopupState.close();
    }
  };

  const handleProductPublished = (event: any) => {
    if (event?.target) {
      if (event?.target?.value === productPublished) {
        setProductPublished('');
      } else {
        setProductPublished(event?.target?.value);
      }
    }
  };

  const handleProductPosting = (event: any) => {
    if (event?.target) {
      if (event?.target?.value === productPosting) {
        setProductPosting('');
      } else {
        setProductPosting(event?.target?.value);
      }
    }
  };

  const handleProductType = () => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['IsShowPublishedOnly', 'IsShowPostingInventoryOnly', 'LastRowNumber']),
        ...(productPublished === 'true' ? {IsShowPublishedOnly: true} : {}),
        ...(productPublished === 'false' ? {IsShowPublishedOnly: false} : {}),
        ...(productPosting === 'true' ? {IsShowPostingInventoryOnly: true} : {}),
        ...(productPosting === 'false' ? {IsShowPostingInventoryOnly: false} : {}),
      });
      productTypePopupState.close();
    }
  };

  const cancelProductType = () => {
    if (setSearchFilter) {
      setProductPosting('');
      setProductPublished('');
    }
    if (
      typeof searchFilter.IsShowPostingInventoryOnly !== 'boolean' &&
      typeof searchFilter.IsShowPublishedOnly !== 'boolean'
    ) {
      productTypePopupState.close();
    }
  };

  const handleCategoryFilter = (selected: Partial<ICategory> | null) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['CategoryIDs[]', 'LastRowNumber']),
        ...(selected ? {'CategoryIDs[]': selected?.ID} : {}),
      });
    }
    setCategoryFilter(selected || undefined);
  };

  const handleBrandFilter = (selected: Partial<IBrand> | null) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['BrandIDs[]', 'LastRowNumber']),
        ...(selected ? {'BrandIDs[]': selected?.ID} : {}),
      });
    }
    setBrandFilter(selected || undefined);
  };

  const handleClassFilter = (selected: Partial<InventoryClass> | null) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['ClassID', 'LastRowNumber']),
        ...(selected ? {ClassID: selected?.ID} : {}),
      });
    }
    setClassFilter(selected || undefined);
  };

  const handleSupplierFilter = (selected: Partial<ISupplier> | null) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['SupplierID', 'LastRowNumber']),
        ...(selected ? {SupplierID: selected?.ID} : {}),
      });
    }
    setSupplierFilter(selected || undefined);
  };

  const handleWarehouseFilter = (selected: Partial<IWarehouse> | null) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['WarehouseID', 'LastRowNumber']),
        ...(selected ? {WarehouseID: selected?.ID} : {}),
      });
    }
    setWarehouseFilter(selected || undefined);
  };

  const closeDialog = (refetch?: boolean) => {
    if (refetch && refetchList) {
      refetchList();
    }
    setOpen(false);
  };

  return (
    <>
      <ModifyDialog open={open} selectedRows={selected || []} onClose={closeDialog} />
      <STableToolbar>
        <div className="flex w-full justify-between pt-2">
          <Typography variant="h3" className={`font-medium`}>
            {title}
          </Typography>
          <div className="flex text-primary">
            <div
              onClick={toggleFilter}
              className="mx-4 flex cursor-pointer items-center text-xl font-medium text-[#4D4D4D]"
            >
              <IconSearch />
              <span className="px-1 pl-2">Search</span>
            </div>
            <div
              onClick={toggleFilter}
              className="mx-4 flex cursor-pointer items-center text-xl font-medium text-[#4D4D4D]"
            >
              <IconFilter />
              <span className="px-1 pl-2 text-xl">Filters</span>
            </div>
            <div
              {...bindTrigger(columnSettingPopupState)}
              className="mx-4 flex cursor-pointer items-center text-xl font-medium text-[#4D4D4D]"
            >
              <IconColumnSetting />
              <span className="px-1 pl-2 text-xl">Column Settings</span>
            </div>
            <Popover
              {...bindPopover(columnSettingPopupState)}
              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
              <div className="flex flex-col p-3 pr-4">
                <Typography className="text-xl font-medium">Fields</Typography>
                <div className="flex flex-col">
                  {Object.entries(columns).map(([key, value]: [string, any]) => (
                    <Checkbox
                      ripple={false}
                      disabled={Boolean(value.disabled)}
                      className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                      key={`columns-${key}`}
                      name={key}
                      label={value.title}
                      checked={selectedColumns.includes(key)}
                      onChange={handleColumnCheckbox}
                    />
                  ))}
                </div>
              </div>
            </Popover>
          </div>
        </div>
        <Collapse in={showFilter} className={'w-full'}>
          <div className="m-2 flex w-full justify-between rounded-lg border-[1px] border-[#ECECEC] bg-[#FAFAFA] p-2">
            <SSearchInput
              loading={loading}
              searchFilter={searchFilter}
              placeholder="Search products…"
              handleChange={handleSearchInput}
            />
            <div className="flex">
              <Button
                variant="text"
                ripple={false}
                className="pt-2 text-base tracking-tight text-[#444343] hover:text-[#444343] hover:no-underline focus:bg-transparent active:bg-transparent"
                {...bindTrigger(categoryPopupState)}
              >
                <Typography className="flex items-center font-semibold" variant="paragraph">
                  Product Category <IconKeyboardArrowDownRounded className="ml-1" />
                </Typography>
              </Button>
              <Popover
                {...bindPopover(categoryPopupState)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
              >
                <div className="flex min-w-[275px] flex-col p-4">
                  <Typography className="text-xs font-medium uppercase text-primary">
                    Select Product Category
                  </Typography>
                  <CategoryAutocomplete
                    styles={{marginTop: '-1.5rem'}}
                    selected={categoryFilter ? categoryFilter : null}
                    clearClick={() => handleCategoryFilter(null)}
                    onSuggestionSelected={(e, selected) => handleCategoryFilter(selected.suggestion)}
                  />
                </div>
              </Popover>
              <Button
                variant="text"
                ripple={false}
                className="pt-2 text-base tracking-tight text-[#444343] hover:text-[#444343] hover:no-underline focus:bg-transparent active:bg-transparent"
                {...bindTrigger(brandPopupState)}
              >
                <Typography className="flex items-center font-semibold" variant="paragraph">
                  Product Brand <IconKeyboardArrowDownRounded className="ml-1" />
                </Typography>
              </Button>
              <Popover
                {...bindPopover(brandPopupState)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
              >
                <div className="flex min-w-[275px] flex-col p-4">
                  <Typography className="text-xs font-medium uppercase text-primary">Select Product Brand</Typography>
                  <BrandAutocomplete
                    styles={{marginTop: '-1.5rem'}}
                    selected={brandFilter ? brandFilter : null}
                    clearClick={() => handleBrandFilter(null)}
                    onSuggestionSelected={(e, selected) => handleBrandFilter(selected.suggestion)}
                  />
                </div>
              </Popover>
              <Button
                variant="text"
                ripple={false}
                className="pt-2 text-base tracking-tight text-[#444343] hover:text-[#444343] hover:no-underline focus:bg-transparent active:bg-transparent"
                {...bindTrigger(classPopupState)}
              >
                {' '}
                <Typography className="flex items-center font-semibold" variant="paragraph">
                  Product Class <IconKeyboardArrowDownRounded className="ml-1" />
                </Typography>
              </Button>
              <Popover
                {...bindPopover(classPopupState)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
              >
                <div className="flex min-w-[275px] flex-col p-4">
                  <Typography className="text-xs font-medium uppercase text-primary">Select Product Class</Typography>
                  <InventoryClassAutocomplete
                    styles={{marginTop: '-1.5rem'}}
                    selected={classFilter ? classFilter : null}
                    clearClick={() => handleClassFilter(null)}
                    onSuggestionSelected={(e, selected) => handleClassFilter(selected.suggestion)}
                  />
                </div>
              </Popover>
              <Button
                variant="text"
                ripple={false}
                className="pt-2 text-base tracking-tight text-[#444343] hover:text-[#444343] hover:no-underline focus:bg-transparent active:bg-transparent"
                {...bindTrigger(supplierPopupState)}
              >
                <Typography className="flex items-center font-semibold" variant="paragraph">
                  Suplier <IconKeyboardArrowDownRounded className="ml-1" />
                </Typography>
              </Button>
              <Popover
                {...bindPopover(supplierPopupState)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
              >
                <div className="flex min-w-[275px] flex-col p-4">
                  <Typography className="text-xs font-medium uppercase text-primary">Select Supplier</Typography>
                  <SupplierAutocomplete
                    styles={{marginTop: '-1.5rem'}}
                    selected={supplierFilter ? supplierFilter : null}
                    clearClick={() => handleSupplierFilter(null)}
                    onSuggestionSelected={(e, selected) => handleSupplierFilter(selected.suggestion)}
                  />
                </div>
              </Popover>
              <Button
                variant="text"
                ripple={false}
                className="pt-2 text-base tracking-tight text-[#444343] hover:text-[#444343] hover:no-underline focus:bg-transparent active:bg-transparent"
                {...bindTrigger(warehousePopupState)}
              >
                <Typography className="flex items-center font-semibold" variant="paragraph">
                  Warehouse <IconKeyboardArrowDownRounded className="ml-1" />
                </Typography>
              </Button>
              <Popover
                {...bindPopover(warehousePopupState)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
              >
                <div className="flex min-w-[275px] flex-col p-4">
                  <Typography className="text-xs font-medium uppercase text-primary">Select Warehouse</Typography>
                  <WarehouseAutocomplete
                    styles={{marginTop: '-1.5rem'}}
                    selected={warehouseFilter ? warehouseFilter : null}
                    clearClick={() => handleWarehouseFilter(null)}
                    onSuggestionSelected={(e, selected) => handleWarehouseFilter(selected.suggestion)}
                  />
                </div>
              </Popover>
              <Button
                variant="text"
                ripple={false}
                className="pt-2 text-base tracking-tight text-[#444343] hover:text-[#444343] hover:no-underline focus:bg-transparent active:bg-transparent"
                {...bindTrigger(productTypePopupState)}
              >
                <Typography className="flex items-center font-semibold" variant="paragraph">
                  Product Type <IconKeyboardArrowDownRounded className="ml-1" />
                </Typography>
              </Button>
              <Popover
                {...bindPopover(productTypePopupState)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
              >
                <div className="flex flex-col p-4">
                  <Typography className="pb-2 text-base text-[#999999]">Published Product</Typography>
                  <RadioGroup aria-label="PostingBehaviour" id="PostingBehaviour" value={productPublished}>
                    <FormControlLabel
                      value="true"
                      control={<Radio onClick={handleProductPublished} />}
                      label="Published Product"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio onClick={handleProductPublished} />}
                      label="Unpublished Product"
                    />
                  </RadioGroup>
                  <Typography className="py-2 text-base text-[#999999]">Posting Product</Typography>
                  <RadioGroup aria-label="PostingBehaviour" id="PostingBehaviour" value={productPosting}>
                    <FormControlLabel
                      value="true"
                      control={<Radio onClick={handleProductPosting} />}
                      label="Posting products only"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio onClick={handleProductPosting} />}
                      label="Non-posting products only"
                    />
                  </RadioGroup>
                  <div className="mt-2 flex flex-row justify-between">
                    <Button variant="outlined" size="sm" onClick={cancelProductType}>
                      {typeof searchFilter.IsShowPostingInventoryOnly === 'boolean' ||
                      typeof searchFilter.IsShowPublishedOnly === 'boolean'
                        ? 'Reset'
                        : 'Cancel'}
                    </Button>
                    <Button size="sm" onClick={handleProductType}>
                      Apply
                    </Button>
                  </div>
                </div>
              </Popover>
              <Button
                variant="text"
                ripple={false}
                className="pt-2 text-base tracking-tight text-[#444343] hover:text-[#444343] hover:no-underline focus:bg-transparent active:bg-transparent"
                {...bindTrigger(physicalPopupState)}
              >
                <Typography className="flex items-center font-semibold" variant="paragraph">
                  Inventory/Fee <IconKeyboardArrowDownRounded className="ml-1" />
                </Typography>
              </Button>
              <FilterMenu {...bindMenu(physicalPopupState)}>
                <FilterMenuItem selected={physical === 'Inventory'} onClick={() => handlePhysicalFilter('Inventory')}>
                  Inventory
                </FilterMenuItem>
                <FilterMenuItem selected={physical === 'Fee'} onClick={() => handlePhysicalFilter('Fee')}>
                  Fee
                </FilterMenuItem>
              </FilterMenu>
              <IconButton aria-label="close" className="text-gray-500" onClick={toggleFilter}>
                <CloseCross className="h-3 w-3 stroke-black-800 " />
              </IconButton>
            </div>
          </div>
        </Collapse>
        <div className="flex w-full justify-between pt-3">
          <div>
            <Checkbox
              label={
                <div>
                  <Typography className="mr-4  text-base font-medium text-primary">
                    Select All ({Array.isArray(data) ? data.length : 0})
                  </Typography>
                </div>
              }
              checked={data?.length ? data?.every(v => selected?.includes(v?.ID)) : false}
              onChange={handleSelectAll}
              ripple={false}
              className="h-5 w-5 rounded-full border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
            />
            <Button
              variant="filled"
              className="mb-4 ml-1"
              size="sm"
              onClick={() => setOpen(true)}
              disabled={Array.isArray(selected) && selected.length ? false : true}
            >
              Modify Selected ({Array.isArray(selected) ? selected.length : 0})
            </Button>
          </div>
          <div className="flex">
            <div className="ml-4">
              <Checkbox
                ripple={false}
                className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                label="Show archive products"
                checked={showArchive}
                onChange={handleArchiveClick}
              />
            </div>
          </div>
        </div>
      </STableToolbar>
    </>
  );
};

export const BrandToolbar: FunctionComponent<Partial<IInventoryToolbar>> = props => {
  const [orderBy, setOrderBy] = useState<string>('Name_true');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [openActivateDialog, setOpenActivateDialog] = useState<boolean>(false);
  const {title, loading, searchFilter, data, selected, refetchList, setSelected, setSearchFilter} = props;

  const {isLoading, deleteBrand, updateBrand} = useBrandAPI();

  const brandOrderPopupState = usePopupState({variant: 'popover', popupId: 'brandOrderPopupState'});

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleSearchInput = (newValue: string) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber']),
        Search: newValue,
      });
    }
  };

  const handleOrderBy = (value: string) => {
    if (setSearchFilter) {
      setOrderBy(value);
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber']),
        SortField: value.split('_')[0],
        SortAsc: value.split('_')[1],
      });
      brandOrderPopupState.close();
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(data) && Array.isArray(selected) && typeof setSelected === 'function') {
      const selectedSet = new Set(selected);

      for (let _data of data) {
        if (event.target.checked) {
          selectedSet.add(_data.ID);
        } else {
          selectedSet.delete(_data.ID);
        }
      }
      setSelected(Array.from(selectedSet));
    }
  };

  const handleShowDeletedClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber']),
        ...{IsShowActiveOnly: !event.target.checked},
      });
    }
    setSelected && setSelected([]);
  };

  const handleActivateBrand = () => {
    if (Array.isArray(selected)) {
      Promise.all(
        selected.map((brandID: number) => {
          if (!searchFilter.IsShowActiveOnly) {
            return updateBrand({ID: brandID, IsActive: true} as IBrand);
          }
          return deleteBrand(brandID);
        }),
      ).then(() => {
        setSelected && setSelected([]);
        refetchList && refetchList();
        setOpenActivateDialog(false);
      });
    }
  };

  return (
    <>
      {openActivateDialog && (
        <AlertDialogSlide
          title={searchFilter.IsShowActiveOnly ? 'Delete Brands' : 'Reactivate Brands'}
          footer={
            <div className="flex w-full justify-between px-4 py-2">
              <Button variant="outlined" onClick={() => setOpenActivateDialog(false)}>
                Cancel
              </Button>
              <Button variant="filled" color="error" onClick={() => handleActivateBrand()}>
                {searchFilter.IsShowActiveOnly ? 'Delete' : 'Reactivate'} Brand
              </Button>
            </div>
          }
        >
          <Typography className="pb-3">
            Are you sure you want to {searchFilter.IsShowActiveOnly ? 'Delete' : 'Reactivate'} Brands ?{' '}
          </Typography>
        </AlertDialogSlide>
      )}
      <STableToolbar>
        <div className="flex w-full justify-between pt-2">
          <Typography variant="h3" className={`font-medium`}>
            {title}
          </Typography>
          <div className="flex text-[#0082BA]">
            <div
              onClick={toggleFilter}
              className="mx-4 flex cursor-pointer items-center whitespace-nowrap text-xl font-medium text-[#4D4D4D]"
            >
              <IconSearch />
              <span className="px-1 pl-2">Search</span>
            </div>
            <div
              onClick={toggleFilter}
              className="mx-4 flex cursor-pointer items-center whitespace-nowrap text-xl font-medium text-[#4D4D4D]"
            >
              <IconFilter />
              <span className="px-1 pl-2 text-xl">Filters</span>
            </div>
          </div>
        </div>
        <Collapse in={showFilter} className={'w-full'}>
          <div className="m-2 flex w-full justify-between rounded-lg border-[1px] border-[#ECECEC] bg-[#FAFAFA] p-2">
            <SSearchInput
              loading={loading}
              searchFilter={searchFilter}
              placeholder="Search brands..."
              handleChange={handleSearchInput}
            />
            <div className="flex">
              <Button
                variant="gradient"
                className="pt-2 text-base tracking-tight"
                {...bindTrigger(brandOrderPopupState)}
              >
                <Typography className="flex items-center font-semibold" variant="paragraph">
                  Order By <IconKeyboardArrowDownRounded className="ml-1" />
                </Typography>
              </Button>
              <FilterMenu {...bindMenu(brandOrderPopupState)}>
                <FilterMenuItem selected={orderBy === 'Name_true'} onClick={() => handleOrderBy('Name_true')}>
                  Order By: Name A to Z
                </FilterMenuItem>
                <FilterMenuItem selected={orderBy === 'Name_false'} onClick={() => handleOrderBy('Name_false')}>
                  Order By: Name Z to A
                </FilterMenuItem>
              </FilterMenu>
              <IconButton aria-label="close" className="text-gray-500" onClick={toggleFilter}>
                <CloseCross className="h-3 w-3 stroke-black-800 " />
              </IconButton>
            </div>
          </div>
        </Collapse>
        <div className="flex w-full justify-between pt-4">
          <div>
            <Checkbox
              label={
                <div>
                  <Typography className="mr-4  text-base font-medium text-primary">
                    Select All ({Array.isArray(data) ? data.length : 0})
                  </Typography>
                </div>
              }
              checked={data?.length ? data?.every(v => selected?.includes(v?.ID)) : false}
              onChange={handleSelectAll}
              ripple={false}
              className="h-5 w-5 rounded-full border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
            />
            {!searchFilter.IsShowActiveOnly && (
              <Button
                className="mb-4 ml-1"
                size="sm"
                onClick={() => setOpenActivateDialog(true)}
                disabled={(Array.isArray(selected) && selected.length ? false : true) || isLoading}
              >
                Reactivate Brands ({Array.isArray(selected) ? selected.length : 0})
              </Button>
            )}
            {searchFilter.IsShowActiveOnly && (
              <Button
                className="mb-4 ml-1"
                color="error"
                size="sm"
                disabled={(Array.isArray(selected) && selected.length ? false : true) || isLoading}
                onClick={() => setOpenActivateDialog(true)}
              >
                Delete
              </Button>
            )}
          </div>
          <div className="flex">
            <div className="mx-4">
              <Checkbox
                className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                ripple={false}
                label="Show Deleted brands"
                checked={!searchFilter.IsShowActiveOnly}
                onChange={handleShowDeletedClick}
              />
            </div>
          </div>
        </div>
      </STableToolbar>
    </>
  );
};

export const CategoryToolbar: FunctionComponent<Partial<IInventoryToolbar>> = props => {
  const [orderBy, setOrderBy] = useState<string>('Name_true');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const {title, loading, searchFilter, setSearchFilter} = props;

  const categoryOrderPopupState = usePopupState({variant: 'popover', popupId: 'categoryOrderPopupState'});

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleSearchInput = (newValue: string) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber']),
        Search: newValue,
      });
    }
  };

  const handleOrderBy = (value: string) => {
    if (setSearchFilter) {
      setOrderBy(value);
      setSearchFilter({
        ...searchFilter,
        SortField: value.split('_')[0],
        SortAsc: value.split('_')[1],
      });
      categoryOrderPopupState.close();
    }
  };

  return (
    <STableToolbar>
      <div className="flex w-full justify-between pt-2">
        <Typography variant="h3" className={`font-medium`}>
          {title}
        </Typography>
        <div className="flex text-[#0082BA]">
          <div
            onClick={toggleFilter}
            className="mx-4 flex cursor-pointer items-center whitespace-nowrap text-xl font-medium text-[#4D4D4D]"
          >
            <IconSearch />
            <span className="px-1 pl-2">Search</span>
          </div>
          <div
            onClick={toggleFilter}
            className="mx-4 flex cursor-pointer items-center whitespace-nowrap text-xl font-medium text-[#4D4D4D]"
          >
            <IconFilter />
            <span className="px-1 pl-2 text-xl">Filters</span>
          </div>
        </div>
      </div>
      <Collapse in={showFilter} className={'w-full'}>
        <div className="m-2 flex w-full justify-between rounded-lg border-[1px] border-[#ECECEC] bg-[#FAFAFA] p-2">
          <SSearchInput
            loading={loading}
            searchFilter={searchFilter}
            placeholder="Search categories..."
            handleChange={handleSearchInput}
          />
          <div className="flex">
            <Button
              variant="gradient"
              className="pt-2 text-base tracking-tight"
              {...bindTrigger(categoryOrderPopupState)}
            >
              <Typography className="flex items-center font-semibold" variant="paragraph">
                Order By <IconKeyboardArrowDownRounded className="ml-1" />
              </Typography>
            </Button>
            <FilterMenu {...bindMenu(categoryOrderPopupState)}>
              <FilterMenuItem selected={orderBy === 'Name_true'} onClick={() => handleOrderBy('Name_true')}>
                Order By: Name A to Z
              </FilterMenuItem>
              <FilterMenuItem selected={orderBy === 'Name_false'} onClick={() => handleOrderBy('Name_false')}>
                Order By: Name Z to A
              </FilterMenuItem>
            </FilterMenu>
            <IconButton aria-label="close" className="text-gray-500" onClick={toggleFilter}>
              <CloseCross className="h-3 w-3 stroke-black-800 " />
            </IconButton>
          </div>
        </div>
      </Collapse>
    </STableToolbar>
  );
};

export const InventoryClassesToolbar: FunctionComponent<Partial<IInventoryToolbar>> = props => {
  const [orderBy, setOrderBy] = useState<string>('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const {title, loading, searchFilter, setSearchFilter} = props;

  const classOrderPopupState = usePopupState({variant: 'popover', popupId: 'classOrderPopupState'});

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleSearchInput = (newValue: string) => {
    if (setSearchFilter) {
      setSearchFilter({
        ...omit(searchFilter, ['LastRowNumber']),
        Search: newValue,
      });
    }
  };

  const handleOrderBy = (value: string) => {
    if (setSearchFilter) {
      setOrderBy(value);
      setSearchFilter({
        ...searchFilter,
        SortField: value.split('_')[0],
        SortAsc: value.split('_')[1],
      });
      classOrderPopupState.close();
    }
  };

  return (
    <STableToolbar>
      <div className="flex w-full justify-between pt-2">
        <Typography variant="h3" className={`font-medium`}>
          {title}
        </Typography>
        <div className="flex text-[#0082BA]">
          <div
            onClick={toggleFilter}
            className="mx-4 flex cursor-pointer items-center whitespace-nowrap text-xl font-medium text-[#4D4D4D]"
          >
            <IconSearch />
            <span className="px-1 pl-2">Search</span>
          </div>
          <div
            onClick={toggleFilter}
            className="mx-4 flex cursor-pointer items-center whitespace-nowrap text-xl font-medium text-[#4D4D4D]"
          >
            <IconFilter />
            <span className="px-1 pl-2 text-xl">Filters</span>
          </div>
        </div>
      </div>
      <Collapse in={showFilter} className={'w-full'}>
        <div className="m-2 flex w-full justify-between rounded-lg border-[1px] border-[#ECECEC] bg-[#FAFAFA] p-2">
          <SSearchInput
            loading={loading}
            searchFilter={searchFilter}
            placeholder="Search classes..."
            handleChange={handleSearchInput}
          />
          <div className="flex">
            <Button variant="gradient" className="pt-2 text-base tracking-tight" {...bindTrigger(classOrderPopupState)}>
              <Typography className="flex items-center font-semibold" variant="paragraph">
                Order By <IconKeyboardArrowDownRounded className="ml-1" />
              </Typography>
            </Button>
            <FilterMenu {...bindMenu(classOrderPopupState)}>
              <FilterMenuItem selected={orderBy === 'Name_true'} onClick={() => handleOrderBy('Name_true')}>
                Order By: Name A to Z
              </FilterMenuItem>
              <FilterMenuItem selected={orderBy === 'Name_false'} onClick={() => handleOrderBy('Name_false')}>
                Order By: Name Z to A
              </FilterMenuItem>
            </FilterMenu>
            <IconButton aria-label="close" className="text-gray-500" onClick={toggleFilter}>
              <CloseCross className="h-3 w-3 stroke-black-800 " />
            </IconButton>
          </div>
        </div>
      </Collapse>
    </STableToolbar>
  );
};
