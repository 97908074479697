import React, {useContext} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {AUTH_ACCOUNTSPAYABLE_ONBOARDING_V1_ROUTE} from '../../routes/AuthenticatedRoutes';
import APOnbBusinessInfo from '../../assets/png/AccountsPayable/APOnbBusinessInfo.png';
import APOnbFinancialAdaptor from '../../assets/png/AccountsPayable/APOnbFinancialAdaptor.png';
import APOnbKYC from '../../assets/png/AccountsPayable/APOnbKYC.png';
import APOnbPaymentMethod from '../../assets/png/AccountsPayable/APOnbPaymentMethod.png';
import APOnbClearing from '../../assets/png/AccountsPayable/APOnbClearingAccount.png';
import APOnbUser from '../../assets/png/AccountsPayable/APOnbUserSetting.png';
import APOnbFinancialAdaptorIcon from '../../assets/svg/accounts-payable/APOnbFinancialAdaptorIcon';
import APOnbBusinessInfoIcon from '../../assets/svg/accounts-payable/APOnbBusinessInfoIcon';
import APOnbAmlKycIcon from '../../assets/svg/accounts-payable/APOnbAmlKycIcon';
import APOnbPaymentMethodIcon from '../../assets/svg/accounts-payable/APOnbPaymentMethodIcon';
import APOnbClearingAccountIcon from '../../assets/svg/accounts-payable/APOnbClearingAccountIcon';
import APOnbUserSettingIcon from '../../assets/svg/accounts-payable/APOnbUserSettingIcon';
import {Button, Checkbox, Tab, Tabs, Typography} from 'spenda-ui-react';
import {APOnboardingStepsName} from '../../model/constants/Constants';
import APOnbProcessingArrowIcon from '../../assets/svg/accounts-payable/APOnbProcessingArrowIcon';
import PSBLOnbPostingConfigurationIcon from '../../assets/svg/PSBLOnbPostingConfigurationIcon';
import AppContext from '../../context/app/appContext';
import {IBaseUser} from '../../model/user/UserAccountInfo';
import useTenantAPI from '../../services/useTenantAPI';
import {ITenant} from '../../model/Tenant';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="font-poppins">{children} </div>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IAccountsPayableModuleInfoTabsProps {
  isPsblModuleInfo?: boolean;
}
export const AccountsPayableModuleInfoTabs = (props: IAccountsPayableModuleInfoTabsProps) => {
  // Props
  const {isPsblModuleInfo} = props;

  // Hooks
  const history = useHistory();

  // Context
  const {user, tenant, setUserTenant} = useContext(AppContext);

  // API
  const {saveTenantDetails, getTermsAndConditions, acceptFeeTerm, acceptTermsAndConditions, isLoading} = useTenantAPI();

  // States
  const [termAndCondition, setTermsAndCondition] = React.useState(false);
  const [isAcknowledgeChk, setIsAcknowledgeChk] = React.useState(false);
  const [selectedBucket, setSelectedBucket] = React.useState(0);

  // Feature Flag
  const {lmiCommercialRelease92671} = useFeatureFlags().supplierDriven();

  const handleBucketChange = (index: number) => {
    setSelectedBucket(index);
  };

  // Tabs data
  const apTabs = [
    {
      label: APOnboardingStepsName.ConfigureFinancialAdaptor,
      icon: <APOnbFinancialAdaptorIcon width="15px" height={'20px'} />,
    },
    {
      label: APOnboardingStepsName.BusinessInformation,
      icon: <APOnbBusinessInfoIcon width="20px" height={'20px'} />,
    },
    {
      label: APOnboardingStepsName.AMLKYC,
      icon: <APOnbAmlKycIcon width="20px" height={'18px'} />,
    },
    {
      label: APOnboardingStepsName.SetupPaymentMethods,
      icon: <APOnbPaymentMethodIcon width="20px" height={'16px'} />,
    },
    {
      label: APOnboardingStepsName.ClearingAccount,
      icon: <APOnbClearingAccountIcon width="20px" height={'14px'} />,
    },
    {
      label: APOnboardingStepsName.UserSettings,
      icon: <APOnbUserSettingIcon width="20px" height={'20px'} />,
    },
  ];

  const psblTabs = [
    {
      label: 'Configure financial adaptor',
      icon: <APOnbFinancialAdaptorIcon width="15px" height={'20px'} />,
    },
    {
      label: lmiCommercialRelease92671 ? 'Book onboarding' : APOnboardingStepsName.BusinessInformation,
      icon: <APOnbBusinessInfoIcon width="20px" height={'20px'} />,
    },
    {
      label: 'Posting configurations',
      icon: <PSBLOnbPostingConfigurationIcon />,
    },
  ];

  const feeSubscriptionText = `I acknowledge that my Capricorn Trade Account will be charged $99 p/m for SwiftStatement by Spenda.`;

  const onClickAPGetStartedBtn = () => history.push(AUTH_ACCOUNTSPAYABLE_ONBOARDING_V1_ROUTE);

  const onClickPsblGetStartedBtn = async () => {
    try {
      const u: Partial<IBaseUser> = user ? user : tenant?.Users?.length ? tenant?.Users?.[0] : {};

      const tenantDetails = {
        Name: u?.TenantName,
        FirstName: u?.FirstName,
        LastName: u?.LastName,
        AgreedTsAndCs: termAndCondition,
        Phone: u?.Phone1,
      };

      if (lmiCommercialRelease92671) {
        const termAndConditionRes = await getTermsAndConditions({ModuleID: null});
        const acceptTermsAndConditionsPromises = termAndConditionRes?.value?.termsAndConditions?.map(t =>
          acceptTermsAndConditions({TermsAndConditionID: t?.termsAndConditionID}),
        );

        await Promise.all([
          acceptFeeTerm({ModuleID: null, SubscriptionText: feeSubscriptionText}),
          ...(acceptTermsAndConditionsPromises?.length ? acceptTermsAndConditionsPromises : []),
        ]);
      }
      await saveTenantDetails(tenantDetails);

      const updatedTenant = {...tenant, AgreedTsAndCs: tenantDetails.AgreedTsAndCs};

      if (updatedTenant && user) {
        setUserTenant({tenant: updatedTenant as ITenant, user: user});
      }

      history.push('/supplier/psbl/onboarding');
    } catch (e) {
      console.error(e);
    }
  };

  // Tabs content data
  const apTabsPanel = [
    {
      title: 'Link your accounting software',
      desc: 'This allows Spenda to integrate with your accounting software and enable real-time information exchange.',
      img: <img src={APOnbFinancialAdaptor} className="mx-auto" width={'145px'} height={'125px'} />,
      onClick: onClickAPGetStartedBtn,
    },
    {
      title: 'Business information',
      desc: 'Add your business information.',
      img: <img src={APOnbBusinessInfo} className="mx-auto" width={'145px'} height={'125px'} />,
      onClick: onClickAPGetStartedBtn,
    },
    {
      title: 'AML/KYC',
      desc: 'To process payments, we require AML (Anti-Money Laundering) and KYC (Know-Your Customer) checks on your business.',
      img: <img src={APOnbKYC} className="mx-auto" width={'145px'} height={'125px'} />,
      onClick: onClickAPGetStartedBtn,
    },
    {
      title: 'Set up payment methods',
      desc: 'Add in your preferred payment for Credit Card, EFTPOS and bank account to account payments.',
      img: <img src={APOnbPaymentMethod} className="mx-auto" width={'145px'} height={'125px'} />,
      onClick: onClickAPGetStartedBtn,
    },
    {
      title: 'Clearing account',
      desc: 'We use this account for costs or amounts that are to be transferred to another account.',
      img: <img src={APOnbClearing} className="mx-auto" width={'145px'} height={'125px'} />,
      onClick: onClickAPGetStartedBtn,
    },
    {
      title: 'User settings and payment authorities',
      desc: 'Delegate what functions users can do within your Accounts Payable module',
      img: <img src={APOnbUser} className="mx-auto" width={'145px'} height={'125px'} />,
      onClick: onClickAPGetStartedBtn,
    },
  ];

  const psblTabsPanel = [
    {
      title: 'Link your accounting software',
      desc: 'This allows Spenda to integrate with your accounting software and enable real-time information exchange.',
      img: <img src={APOnbFinancialAdaptor} className="mx-auto" width={'145px'} height={'125px'} />,
      onClick: onClickPsblGetStartedBtn,
    },
    {
      title: lmiCommercialRelease92671 ? 'Book Onboarding' : 'Business information',
      desc: lmiCommercialRelease92671
        ? 'Book a time with our implementation specialists to complete your setup.'
        : 'Add your business information which will be used on invoices, credits and more.',
      img: <img src={APOnbBusinessInfo} className="mx-auto" width={'145px'} height={'125px'} />,
      onClick: onClickPsblGetStartedBtn,
    },
    {
      title: 'Posting configurations',
      desc: 'Configure how transactions map to Suppliers and Inventory in your Financial System.',
      img: <img src={APOnbClearing} className="mx-auto" width={'145px'} height={'125px'} />,
      onClick: onClickPsblGetStartedBtn,
    },
  ];

  // Constants
  const renderTabs = isPsblModuleInfo ? psblTabs : apTabs;
  const renderTabsPanel = isPsblModuleInfo ? psblTabsPanel : apTabsPanel;

  return (
    <div className="flex min-h-[500px] flex-row items-center">
      <Tabs
        orientation="horizontal"
        value={0}
        aria-label="simple tabs example"
        className="flex w-full flex-row items-center justify-between"
      >
        <div className="flex min-w-[330px] flex-col gap-2.5">
          {renderTabs.map((renderTabs, index) => (
            <Tab
              value={renderTabs?.label}
              {...a11yProps(index)}
              key={index}
              onClick={() => handleBucketChange(index)}
              className="[&>div:nth-child(n+1)]:!left-[8px] [&>div:nth-child(n+1)]:!top-[8px] [&>div:nth-child(n+1)]:h-[50px] [&>div:nth-child(n+1)]:w-[314px] "
            >
              <div className="flex h-[54px] w-full flex-row items-center justify-between rounded-[10px] bg-white px-5 py-4 shadow-md">
                <div className="flex flex-row items-center justify-start gap-5">
                  {renderTabs?.icon} <p>{renderTabs?.label}</p>
                </div>
                {selectedBucket === index && <APOnbProcessingArrowIcon />}
              </div>
            </Tab>
          ))}
        </div>
        <div className="flex w-[54%] flex-col items-center justify-center px-6 font-poppins">
          {renderTabsPanel.map((renderTabsPanel, index) => (
            <TabPanel value={selectedBucket} index={index} key={index}>
              <div className="flex flex-col items-center text-center font-poppins">
                {renderTabsPanel?.img}
                <h3 className="mt-2 max-w-[300px] text-base font-semibold">{renderTabsPanel?.title}</h3>
                <p className="mt-2 max-w-[300px] text-sm text-spenda-labeltext">{renderTabsPanel?.desc}</p>
                <div className="mt-8 flex flex-col items-center justify-center gap-y-1">
                  {isPsblModuleInfo && (
                    <div className="flex flex-col justify-start">
                      <Checkbox
                        ripple={false}
                        color="primary"
                        data-autoid={`chkTermsAndConditions`}
                        checked={termAndCondition}
                        onClick={() => setTermsAndCondition(!termAndCondition)}
                        containerProps={{className: 'p-0'}}
                        className="border-primary bg-white before:h-5 before:w-5 checked:border-none checked:bg-primary hover:before:opacity-0"
                        label={
                          <p className="font-light">
                            Accept the{' '}
                            <Link
                              to={{pathname: 'https://spenda.co/swiftstatement-terms-conditions/'}}
                              target="_blank"
                              className="cursor-pointer underline"
                            >
                              Terms and Conditions
                            </Link>
                          </p>
                        }
                        labelProps={{className: 'px-2 text-black-800 font-normal text-base'}}
                      />
                      {lmiCommercialRelease92671 && (
                        <Checkbox
                          ripple={false}
                          color="primary"
                          data-autoid={`chkIsAcknowledge`}
                          checked={isAcknowledgeChk}
                          onClick={() => setIsAcknowledgeChk(!isAcknowledgeChk)}
                          containerProps={{className: 'p-0'}}
                          className="before: border-primary bg-white before:h-4 checked:border-none checked:bg-primary hover:before:opacity-0"
                          label={feeSubscriptionText}
                          labelProps={{className: 'text-black-800 pt-4 text-base font-light text-left px-3'}}
                        />
                      )}
                    </div>
                  )}
                  <Button
                    className="my-3 w-[152px]"
                    loading={isLoading}
                    disabled={
                      isPsblModuleInfo
                        ? lmiCommercialRelease92671
                          ? !termAndCondition || !isAcknowledgeChk
                          : !termAndCondition
                        : false
                    }
                    onClick={renderTabsPanel?.onClick}
                  >
                    Get started
                  </Button>
                  {isPsblModuleInfo && (
                    <Typography variant="xsmall" className="text-justify">
                      *Members using eligible accounting platforms can trial SwiftStatement for free for the month you
                      sign up and the prior month. You will be charged $99 p/m (including GST) to your Capricorn Trade
                      Account starting on the second day of the month following sign up unless you cancel beforehand.
                      For example, if you sign up for a trial in November, you can reconcile September and October, and
                      you will not be charged if you cancel by 1 December.
                    </Typography>
                  )}
                  {!isPsblModuleInfo && (
                    <Button
                      variant="text"
                      className="font-semibold text-[#999999] focus:ring-0 active:bg-transparent"
                      ripple={false}
                      onClick={() => {
                        history.push(`${AUTH_ACCOUNTSPAYABLE_ONBOARDING_V1_ROUTE}?skipAdapter=true`);
                      }}
                    >
                      Not now
                    </Button>
                  )}
                </div>
              </div>
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </div>
  );
};
