import React from 'react';
import {toast} from 'react-toastify';
import {Typography} from 'spenda-ui-react';

export class Toast {
  public static info = (msg: string) =>
    toast.info(msg, {
      position: 'top-center',
      style: {background: 'rgb(85, 146, 173)', borderRadius: '8px', color: 'white'},
      icon: false,
      progressStyle: {background: 'white'},
    });

  public static error = (msg: string) =>
    toast.error(msg, {
      position: 'top-center',
      style: {
        background: '#C55D44',
        borderRadius: '4px',
        color: 'white',
        textDecorationColor: 'white',
      },
      icon: false,
      progressStyle: {background: 'white'},
    });

  public static warning = (msg: string) =>
    toast.warn(msg, {
      position: 'top-center',
      theme: 'light',
      autoClose: 5000,
      style: {
        background: '#FFF8EC',
        borderRadius: '8px',
        width: 'inherit',
        color: 'black',
        textDecorationColor: 'black',
      },
    });

  public static multiLineWarning = (message: string[]) =>
    toast.warn(
      <div>
        {message.map((msg, index) => {
          return <Typography variant="paragraph" key={index}>{`${index + 1}. ${msg}`}</Typography>;
        })}
      </div>,
      {
        position: 'top-center',
        theme: 'light',
        autoClose: 5000,
        style: {
          background: '#FFF8EC',
          borderRadius: '8px',
          width: 'inherit',
          color: 'black',
          textDecorationColor: 'black',
        },
      },
    );
}
