import React, {FunctionComponent, ReactNode} from 'react';
import clsx from 'clsx';
import {css} from 'glamor';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';

import LoadingIndicator from '../ui/LoadingIndicator';
import {StyledCustomCheckbox} from '../inputs/SCheckbox';
import {RoundedCheckbox} from '../inputs/RoundedCheckbox';

export interface StyleProps {
  newUI: boolean;
}
export interface IHeaderData {
  align?: 'right' | 'left' | 'center';
  className?: string;
  disabled?: boolean;
  field: string;
  hide?: boolean;
  title: string;
  width?: string;
  render?: (rowData: any) => string | ReactNode;
}

export interface ISTable {
  newUI?: boolean;
  columns: IHeaderData[];
  data: any[];
  emptyDataMessage?: string;
  loading?: boolean;
  selection?: boolean;
  isUpdatedSelect?: boolean;
  selected?: number[];
  setSelected?: (selected: number[]) => void;
  sortable?: boolean;
  stickyHeader?: boolean;
  onRowClick?: ((rowData: any) => void) | undefined;
  rowClassName?: string;
}

const useSTableStyles = makeStyles<Theme, StyleProps>(() => ({
  container: {
    overflowX: 'initial',
  },
  table: {
    minWidth: 750,
  },
  headRow: {
    '& th': {
      fontFamily: 'inherit',
      padding: props => (props.newUI ? '10px 12px' : '16px'),
      fontSize: props => (props.newUI ? '14px' : '16px'),
      fontWeight: props => (props.newUI ? 600 : 500),
      backgroundColor: props => (props.newUI ? '#F1F1F1' : ''),
      '&:first-child': {
        borderTopLeftRadius: '2px',
      },
      '&:last-child': {
        borderTopRightRadius: '2px',
      },
    },
  },
  tableRow: {
    '&:hover td': {
      // backgroundColor: 'rgba(28, 120, 173,0.050) !important',
      backgroundColor: 'hsl(var(--primary-background)) !important',
    },
    '& td': {
      fontFamily: 'inherit',
      fontSize: props => (props.newUI ? '12px' : '18px'),
      padding: props => (props.newUI ? '10px 12px' : '16px'),
      '&:nth-of-type(odd)': {
        backgroundColor: props => (props.newUI ? '#FAFAFA' : ''),
      },
    },
  },
}));

const StyledTableCell = withStyles(() => ({
  stickyHeader: {
    backgroundColor: 'transparent',
    top: '-1.15rem',
  },
  head: {
    color: '#999999',
    fontSize: '16px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  root: {
    borderBottom: 'none',
  },
  body: {
    color: '#333333',
    fontSize: '18px',
    fontWeight: 500,
    borderBottom: '1px solid #EAEAEA60',
  },
}))(TableCell);

const STableHead: FunctionComponent<Partial<ISTable>> = ({newUI = false, ...props}) => {
  const classes = useSTableStyles({newUI});
  return (
    <TableHead>
      <TableRow className={classes.headRow}>
        {props.selection ? <StyledTableCell padding="checkbox" /> : null}
        {Array.isArray(props.columns)
          ? props.columns
              .filter(headCell => !headCell.hide)
              .map((headCell, index) => {
                return (
                  <StyledTableCell
                    key={index}
                    align={headCell.align ? headCell.align : 'left'}
                    width={headCell.width || ''}
                  >
                    {headCell.title}
                  </StyledTableCell>
                );
              })
          : null}
      </TableRow>
    </TableHead>
  );
};

export const STable: FunctionComponent<ISTable> = props => {
  const {newUI = false, columns, data, selection, emptyDataMessage, loading, selected, setSelected, onRowClick} = props;
  const classes = useSTableStyles({newUI});

  const renderFieldData = (row: any, head: IHeaderData) => {
    if (typeof head.render === 'function') {
      return head.render(row) || '-';
    }
    return row[head.field] || '-';
  };

  const handleRowClick = (rowData: object) => {
    if (typeof onRowClick === 'function') {
      onRowClick(rowData);
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(selected) && typeof setSelected === 'function') {
      const selectedSet = new Set(selected);

      if (event.target.checked) {
        selectedSet.add(parseInt(event.target.name));
      } else {
        selectedSet.delete(parseInt(event.target.name));
      }
      setSelected(Array.from(selectedSet));
    }
  };

  const rowClickCSS = typeof onRowClick === 'function' ? css({cursor: 'pointer'}) : {};

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader={false} aria-labelledby="tableTitle">
        <STableHead newUI={newUI} columns={columns} selection={selection} />
        <TableBody>
          {!loading ? (
            data.map((row, index) => (
              <TableRow
                role="checkbox"
                className={classes.tableRow}
                aria-checked={true}
                tabIndex={index}
                key={index}
                {...rowClickCSS}
                onClick={() => handleRowClick(row)}
                data-autoid={`btnTableRow${index}`}
              >
                {selection && props.isUpdatedSelect ? (
                  <StyledTableCell padding="checkbox">
                    <RoundedCheckbox
                      name={row.ID + ''}
                      checked={Array.isArray(selected) ? selected.includes(row.ID) : false}
                      onClick={e => e.stopPropagation()}
                      onChange={handleSelectChange}
                    />
                  </StyledTableCell>
                ) : selection ? (
                  <StyledTableCell padding="checkbox">
                    <StyledCustomCheckbox
                      name={row.ID + ''}
                      checked={Array.isArray(selected) ? selected.includes(row.ID) : false}
                      onClick={e => e.stopPropagation()}
                      onChange={handleSelectChange}
                    />
                  </StyledTableCell>
                ) : null}
                {columns
                  .filter(headCell => !headCell.hide)
                  .map((headCell, index) => {
                    if (index === 0 && selection) {
                      return (
                        <StyledTableCell
                          key={index}
                          component="th"
                          id={`spenda-table-checkbox-${index}`}
                          scope="row"
                          align={headCell.align ? headCell.align : 'left'}
                        >
                          {renderFieldData(row, headCell)}
                        </StyledTableCell>
                      );
                    }
                    return (
                      <StyledTableCell
                        className={`${headCell.className || ''}`}
                        key={index}
                        align={headCell.align ? headCell.align : 'left'}
                      >
                        {renderFieldData(row, headCell)}
                      </StyledTableCell>
                    );
                  })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <StyledTableCell colSpan={selection ? columns.length + 1 : columns.length} align="center">
                <LoadingIndicator isLoading size={'md'} />
              </StyledTableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading ? (
            <TableRow>
              <StyledTableCell
                colSpan={selection ? columns.length + 1 : columns.length}
                align="center"
                className={clsx({'!font-poppins !text-xs !font-semibold': newUI})}
              >
                {emptyDataMessage || 'No Records Found'}
              </StyledTableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
