export enum ConfirmationType {
  Email = 1,
  SupplierMarketPlacePath,
}

export enum StorageKeys {
  AutoLogin = 'AutoLogin',
  User = 'User',
  MarketplaceSupplier = 'MarketplaceSupplier',
  Skip2saPopup = 'Skip2saPopup',
  SelectedAccount = 'SelectedAccount',
  SelectedTemplate = 'SelectedTemplate',
  SalesColumnSetting = 'SalesColumnSetting',
  PurchaseInvoicingColumnSetting = 'PurchaseInvoicingColumnSetting',
}

export enum MarketplaceConnectionStatus {
  NotConnected = 0,
  ConnectionPending = 1,
  Connected = 2,
  Disconnected = 3,
  NonExistent = 4,
}

export enum PurchaseOrderStatus {
  Open = 'Open',
  Sent = 'Sent',
  Sending = 'Sending',
  Sent_as_Unlinked = 'Sent_as_Unlinked',
  Review = 'Review',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Approved = 'Approved',
}

export enum IAlertTypes {
  Email = 0,
  SMS = 1,
  Notification = 2,
  Print = 3,
  Pdf = 'Pdf',
}

export enum DepositAmountType {
  Percentage = 'Percentage',
  FlatFee = 'FlatFee',
  NoDeposit = 'NoDeposit',
}

export enum ModuleTypes {
  System = 0, //Reach System Data. Required by All Modules
  Common = 1, //Base Reach Data. Required by All Modules
  Sales = 2, //Sales Module for Managing Mobile Sales Team
  SpendaStock = 3, //Warehouse Module for Stock Control and Management
  Service = 4, //Service Module for Managing Mobile Service Team
  Invoice = 5, //Invoice Module for Invoicing Sales and Service Items
  Contract = 6, //Contract Module for Loaning and Service SLA Agreements of Assets
  SpendaBuy = 7, //Purchasing module for inventory/stock purchases
  Campaigns = 8, //Campaign marketing module
  Project = 9,
  Payroll = 10,
  Quotes = 12, //quote app
  Claims = 13, //customer side - claim app
  ClaimCenter = 14, //supplier side - claim requests
  Synkd = 20, //Integration Module
  FlashConvert = 21,
  SpendaECom = 22,
  Recvd = 30, //Recvd
  Claimd = 31,
  Retrnd = 32,
  Ordrd = 41,
  Sold = 42,
  Bernie = 43,
  SpendaCollectAR = 50,
  Caplink = 51,
  SpendaService = 52,
  Inventory = 60,
  Customer = 61,
  Supplier = 62,
  Report = 63,
  SpendaPOS = 64,
  SpendaMarketBuying = 65,
  SpendaMarketSelling = 66,
  WaterTesting = 67,
  SpendaCollectCustomerPortal = 68,
  SpendaCollectConnectedStatements = 69,
  ConnectedPayments = 70,
  SpendaPay = 71,
  ProductManagement = 72,
  TradeFinance = 73,
  Suppliers = 74,
  ReceiveStock = 75,
  CustomerManagement = 76,
  Payments = 77,
  SalesOrderManagement = 78,
  Statements = 80,
  MerchantPortal = 81,
  Admin = 1000, //Reach Platform Administration
  Portal = 2000, //Reach Portal
  VirtualCreditFacility = 100,
  TradeAccount = 101,
}

export enum DatTypes {
  Tenants = 0,
  Customers = 1,
  Inventory = 2,
  Assets = 3,
  Businesses = 4,
  InventoryFeatures = 5,
  Fees = 6,
  LabourRates = 7,
  BusinessContacts = 8,
  Users = 9,
  Activity = 10,
  Manufacturers = 11,
  Suppliers = 12,
  ManufacturersList = 13,
  ManufacturerModel = 14,
  Brands = 15,
  Teams = 16,
  Tickets = 17,
  Vouchers = 18,
  BrandRange = 19,
  Category = 20,
  SalesOrder = 21,
  Quotes = 22,
  SalesOrderTemplate = 23,
  SalesOrderUpdate = 24,
  SalesOrderMessage = 25,
  SalesOrderAttachment = 26,
  SalesOrderInvite = 27, //supplier to customer invite to connect/link, where the customer is already registered
  SalesOrderInviteWithPDF = 28, //supplier to customer invite with a Sales Order PDF attachment
  SalesOrderQuoteTotalsOnly = 29, //displays the total prices but excludes all line item prices
  SalesOrderQuoteDetailed = 30, //that includes all line items prices
  InventoryAdjustmentLog = 31,
  PickSlip = 32,
  DeliveryNote = 33,
  InventoryMovement = 34,
  StockReplenishment = 35,
  StockTake = 36,
  DeliveryReceipt = 37,
  InventoryFulfilment = 38,
  Reports = 40,
  ServiceJob = 41,
  ServiceJobTemplates = 42,
  ServiceJobAllocation = 43,
  CreditNoteUpdate = 49,
  InvoiceUpdate = 50,
  Invoice = 51,
  Payment = 52,
  Statement = 53,
  CreditNote = 54,
  DebitNote = 56,
  UnallocatedPayment = 57,
  InvoiceInvite = 58, //supplier to customer invite to connect/link, where the customer is already registered
  InvoiceInviteWithPDF = 59, //supplier to customer invite with a Invoice PDF attachment
  OverdueAndDueInvoiceReminder = 138,
  ModuleRegistration = 60,
  Contracts = 61,
  ContractPricing = 62,
  ContractServiceInvoice = 63,
  ContractSchedules = 64,
  ContractTemplate = 65,
  ContractEvent = 66,
  ContractRole = 67,
  ContractTemplateSchedule = 68,
  InAppPurchase = 69,
  PurchaseRequisition = 70,
  PurchaseOrder = 71,
  PurchaseInvoice = 72,
  PurchaseOrderTemplate = 73,
  PurchaseOrderUpdate = 74,
  PurchaseOrderMessage = 75,
  PurchaseOrderAttachment = 76,
  PurchaseOrderInvite = 77, //customer to supplier invite to connect/link, where the supplier is already registered
  PurchaseOrderInviteWithPDF = 78, //customer to supplier invite with a Purchase Order PDF attachment
  InvoicePaymentAllocation = 79,
  Campaign = 81,
  Prospect = 82,
  Opportunity = 83,
  ReferredOpportunity = 84,
  Tag = 85,
  MediaFile = 86,
  Specific = 87,
  Warehouse = 90,
  WarehouseType = 91,
  WarehouseLocation = 92,
  Container = 93,
  PricingBands = 101,
  InventoryClass = 102,
  InventoryClassGroup = 103,
  InventoryClassGroupType = 104,
  CustomerClass = 105,
  InventoryFavourite = 106,
  Project = 110,
  Claim = 120,
  ClaimRequest = 121,
  ClaimMessage = 122,
  ClaimAttachment = 123,
  Invite = 124,
  Notification = 125,
  ClaimRequestMessage = 126,
  ClaimRequestAttachment = 127,
  CustomerClaimsInvite = 128, //supplier to customer invite with no claimrequest
  CustomerLinkUpdate = 130,
  SupplierLinkUpdate = 131,
  ClaimRequestUpdate = 133, //customer to supplier claim request update (messages, withdraw claim, accept/reject rejection)
  SupplierClaimsInviteWithClaimPDF = 134, //customer to supplier invite with a Claim PDF attachment
  CustomerClaimsInviteWithClaimRequestPDF = 135, //supplier to customer invite with a ClaimRequest PDF attachment
  RegisteredCustomerInviteViaClaimRequest = 137, //supplier to customer invite when the customer is already registered(no claim request sent)
  SupplierInventoryLinks = 136,
  // payroll
  Employee = 140,
  PayrollTypes = 141,
  DeductionType = 142,
  EarningsRate = 143,
  LeaveType = 144,
  ReimbursementType = 145,
  SuperFund = 146,
  // payroll
  //BankTransactions
  Accounts = 150,
  BankTransfer = 151,
  SpendMoney = 152,
  SpendMoneyOverPayment = 153,
  ReceiveMoney = 154,
  ReceiveMoneyOverPayment = 155,
  ManualJournals = 156,
  BankTransaction = 157,
  SpendMoneyPrePayment = 158,
  ReceiveMoneyPrePayment = 159,

  POSTerminal = 200,
  POSTerminalShift = 201,

  InvoicePaymentAllocationAdvice = 209,
  InvoicePaymentAllocationReceipt = 79,

  ClaimDocument = 213,

  Requisition = 250,

  ConsignmentNote = 300,

  AccountsPayablePurchaseInvoice = 508,
  InventorySKU = 1001,
  Note = 1002,
  ReferenceCheck = 1003,
  ReferenceCheckNote = 1004,
  AssetBarcode = 1005,
  InventoryBatchLinkRequest = 1006,

  PortalQuoteRequests = 2001,
  BookingRequests = 2002,
  ProspectiveCustomers = 2003,
  PortalIndustryTypes = 2004,
  PortalIndustrySubTypes = 2005,
  PortalEquipmentTypes = 2006,
  PortalEquipmentSubTypes = 2007,
  EquipmentTypeXProductType = 2008,
  EquipmentTypeXProductTypeXBrand = 2009,
  Vendor = 2012,
  PortalCustomers = 2013,

  //PortalSystemPage = 2014, -> PageTypes.System
  ContactUs = 2015,
  QuoteRejectReasons = 2016,
  CustomerRegistration = 2017,
  VendorRegistration = 2018,
  Skills = 2019,
  ServiceTypes = 2020,
  Faults = 2021,
  ProductTypes = 2022,
  Features = 2023,
  ReferAFriend = 2024,
  Survey = 2025, // Sebastian Cleaning Contracts AllocatedReport
  PasswordResetConfirm = 2026, // Alert type - for email used to get an confirmation response to reset the password
  PasswordReset = 2027, // Alert type - for email to issue the temp password after a reset
  PasswordChanged = 2028, // Alert type - for email to confirm the users password has been changed
  EquipmentSubTypes_I_ProductTypes_I_Brands = 2029,
  EquipmentSubTypes_I_ProductTypes_I_BrandRanges = 2030,
  SendUserTempPassword = 2031, // Alert type - for email used to issue the temp password to a user
  Reminder = 2032, // Alert type - generic
  CustomerQuoteRequest = 2035, // Alert type - Details of request sent to customer
  CustomerQuoteRequestEmailConfirmation = 2036, // Alert type - Asking the customer to confirm to proceed with request
  VendorOpportunityOffer = 2037, // Alert type - Send to Vendor with Accept and Reject links
  VendorOpportunityOfferAccepted = 2038, // Alert type - Full details following accept
  BookingRequestAcknowledgement = 2039,
  BookingConfirmation = 2040,
  BookingRescheduleConfirmation = 2041,
  ServiceJobCompletedConfirmation = 2042,
  BookingReminder = 2043,
  EmailAddressConfirmation = 2044, // Alert type - sent to a user to confirm their email address to complete membership registration
  WelcomeMessage = 2045, // Alert type - sent to a new customer following registration.
  Authorisation = 2503,
  Facebook = 3000,
  GooglePlus = 3001,
  Twitter = 3002,
  LinkedIn = 3003,
  Pinterest = 3004,
  FileDetails = 4000,
  StockTakeCSVFileImport = 4001,
  SendPayInvoiceByLink = 5100,
  AMLKYCApplication = 6001,
  StagedPurchaseInvoice = 251,
  StagedDebitNote = 252,
  CustomerStatements = 7000,
  QuoteMailTemplate = 7100,
}

export enum PaymentStatusType {
  Paid = 'Paid',
  Failed = 'Failed',
  Pending = 'Pending',
  Denied = 'Denied',
  Refunded = 'Refunded',
  Reversed = 'Reversed',
  CanceledReversal = 'CanceledReversal',
  PartiallyRefunded = 'PartiallyRefunded',
  Voided = 'Voided',
  Expired = 'Expired',
  InProgress = 'InProgress',
  Created = 'Created',
  Cancelled = 'Cancelled',
  Cleared = 'Cleared',
  AwaitingAuthorisation = 'AwaitingAuthorisation',
  Scheduled = 'Scheduled',
  CancellationInProgress = 'CancellationInProgress', // UI only
}

export enum PaymentMethodTypes {
  Afterpay = 'Afterpay',
  AmericanExpress = 'AmericanExpress',
  AppleiTunes = 'AppleiTunes',
  BankTransfer = 'BankTransfer',
  Caplink = 'Caplink',
  Cash = 'Cash',
  Certegy = 'Certegy',
  Cheque = 'Cheque',
  CreditCard = 'CreditCard',
  DinersClub = 'DinersClub',
  DirectDebit = 'DirectDebit',
  DirectDeposit = 'DirectDeposit',
  EasyPay = 'EasyPay',
  Eftpos = 'Eftpos',
  GiftCard = 'GiftCard',
  GooglePlay = 'GooglePlay',
  Invigo = 'InvigoPayLater',
  LendingFacility = 'LendingFacility',
  Mastercard = 'Mastercard',
  Paymark = 'Paymark',
  PaymentExpress = 'PaymentExpress',
  PayPal = 'PayPal',
  Square = 'Square',
  UnionPay = 'UnionPay',
  Unknown = 'unknown',
  Visa = 'Visa',
  zipMoney = 'zipMoney',
  zipPay = 'zipPay',
  MasterCardTrackBPA = 'McTrackBPA_Card',
  Airplus = 'AirPlus',
}

export enum BankAccounts {
  ANZ = 'Australia & New Zealand Banking Group Limited',
  BANKWEST = 'Bankwest',
  CBA = 'Commonwealth Bank of Australia',
  NAB = 'National Australia Bank Limited',
  WESTPAC = 'Westpac Banking Corporation',
}

export enum PaymentProcessStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  COMPLETE = 'COMPLETE',
}

export enum TransactionSubType {
  Invoice = 'Invoice',
  MerchantSurcharge = 'MerchantSurcharge',
}

export enum PaymentOutcomeStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  SUBMITTED = 'SUBMITTED',
  TIMEOUT = 'TIMEOUT',
  PARTIAL = 'PARTIAL',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
}

export enum RequestedList {
  None = 0,
  Overdue = 1,
  Due = 2,
  Credits = 3,
  Search = 4,
  Payable = 5,
  PaymentHistory = 6,
}

export enum CustomerRequestedList {
  Overdue = 1,
  Due = 2,
  Credits = 3,
  Search = 4,
}

export enum TwoStepAuthType {
  Email = 1,
  AuthenticatorApp = 2,
  SMS = 3,
}

export enum UserLoginStatuses {
  Pending = 1,
  Successful = 2,
  FailedLogin = 3,
  FailedMFA = 4,
  Cancelled = 5,
}

export enum RequestedListName {
  Overdue = 'Overdue',
  Due = 'Due soon',
  Credits = 'Unallocated Credits',
  Search = 'Search',
  Payable = 'Payable',
  PaymentHistory = 'Payment History',
  None = '',
}

export enum RequestedListShortName {
  Overdue = 'Overdue',
  Due = 'Due Soon',
  Credits = 'Credits',
  Search = 'Search',
  PaymentHistory = 'Payment History',
}

export enum RequestListNoRecordsMessage {
  Overdue = `There are no 'overdue' invoices at this time`,
  Due = `There are no 'due soon' invoices at this time`,
  Credits = `There are no 'unallocated payments and credits' at this time`,
  Search = `No invoices found`,
  PayMode = `No invoices to pay`,
  None = `Please Select any Filter to View your Invoices/Credits/Payments`,
  Payable = `There are no 'overdue/due soon' invoices at this time`,
  PaymentHistory = `No Payment history recorded`,
}

export enum DateFormat {
  DD_MMM_YYY = 'DD MMM YYYY',
}

export enum PaymentProviderName {
  Spenda_Payment_Services = 'Spenda Payment Services',
}

export enum PaymentMethodName {
  SpendaPaymentServices = 'SpendaPaymentServices',
}

export enum VerificationStatus {
  NOT_SET = 'NotSet', // Client side only, for when we've created an account but not set it up yet
  NOT_STARTED = 'NotStarted',
  PENDING = 'Pending',
  FAILED = 'Failed',
  RETRY_REQUIRED = 'RetryRequired',
  ATTEMPTS_EXCEEDED = 'AttemptsExceeded',
  NOT_VERIFIED = 'NotVerified',
  VERIFIED = 'Verified',
  UNKNOWN = 'Unknown',
}

export enum AccountUsage {
  SUPPLIER = 'Supplier',
  BUYER = 'Buyer',
}

export enum AccountType {
  BANK_TRANSFER = 'BankTransfer',
  CREDIT_CARD = 'CreditCard',
  BNPL = 'InvigoPayLater',
  SPENDA_FINANCE = 'MarketplaceFacility',
  VIRTUAL_CARD = 'VirtualCard',
}

export enum PaymentAccountStatus {
  NEW = 'New',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  FAILED = 'Failed',
  DELETED = 'Deleted',
  PayMode = `No invoices to pay`,
}

export enum MenuTypes {
  Buying = 'Buying',
  Selling = 'Selling',
  Inventory = 'Inventory',
}

export enum ViewTypes {
  List = 'List',
  Group = 'Group',
}

export enum PurchaseInvoiceViewTypes {
  SingleInvoiceView = 'Standard view',
  SupplierView = 'Supplier view',
  TimelineView = 'Timeline view',
}

export enum SupplierViewSortingTypes {
  OverduePercentage = 'OverduePercentage',
  OverdueInvoice = 'OverdueInvoice',
  InvoiceTotal = 'InvoiceTotal',
  SupplierName = 'SupplierName',
}

export enum TimelineCustomRange {
  SevenToTwentyEightDays = 1,
  ThirtyToNinetyDays = 2,
  TwoToSixMonths = 3,
  ThreeToNineMonths = 4,
}

export const TimelineCustomRangeDays = ['0_7_14_28', '0_30_60_90', '0_2_4_6', '0_3_6_9'];

export enum SortViewTypes {
  InWeek = 'Due In A Week',
  InFortnight = 'In A Fortnight',
  AMonth = '1 Month',
}

export enum ManageSchedulerTime {
  Minutes = 60,
  Hours = 3600,
  Days = 86400,
}

export enum PurchaseInvoiceRequestedList {
  None = 0,
  Overdue = 1,
  Due = 2,
  Search = 3,
  Payments = 4,
  Payable = 5,
}

export enum InvoiceStatusTypes {
  Overdue = 0,
  Unfinalised = 1,
  Finalised = 2,
  Paid = 3,
  Cancelled = 4,
  Refunded = 5,
}

export enum TransTypes {
  CreditNote = 'CreditNote',
  Invoice = 'Invoice',
  Claim = 'Claim',
  Prepayment = 'Prepayment',
}

export enum ModuleCategories {
  Inventory = 'Inventory',
  Buying = 'Buying',
  Selling = 'Selling',
}

export enum AdaptorSyncDataActions {
  Read = 'Read',
  Write = 'Write',
}

export enum MasterAdaptors {
  Xero = 1,
  Myob = 18,
  Quickbooks = 17,
}

export enum FinancialAdaptors {
  Xero = 'xero',
  QuickBooksOnline = 'quickbooks',
  MyObAccountRightLive = 'myob',
  Parody = 'parody',
}

export enum FinancialAdaptorsType {
  Xero = 'Xero',
  QuickBooksOnline = 'QuickBooksOnline',
  MyObAccountRightLive = 'MyObAccountRightLive',
  Parody = 'Parody',
}

export enum FetchAdaptorDataStatus {
  STARTED = 'Started',
  NOTSTARTED = 'NotStarted',
  SUCCESSFUL = 'Successful',
  FAILED = 'Failed',
}

export enum Actions {
  add = 'Add',
  update = 'Update',
  remove = 'Remove',
}

export enum ModuleStatus {
  SubscriptionActive = 'SubscriptionActive',
  TrialExpired = 'TrialExpired',
  SubscriptionCancelled = 'SubscriptionCancelled',
  TrialActive = 'TrialActive',
  TrialAvailable = 'TrialAvailable',
  ComingSoon = 'ComingSoon',
  SetupRequired = 'SetupRequired',
}

export enum SALES_ORDER_STATUS {
  OPEN = 'Open',
  FINALISED = 'Finalised',
  APPROVED = 'Approved',
  CLOSED = 'Closed',
  CANCELLED = 'Cancelled',
}

export enum SALES_ORDER_STATUS_LABEL {
  Open = 'Draft',
  Cancelled = 'Cancelled',
  Reserved = 'Reserved',
  Approved = 'Invoiced',
}

export enum SalesOrderInvoicingStatus {
  NotInvoiced = 'NotInvoiced',
  PartiallyInvoiced = 'PartiallyInvoiced',
  Invoiced = 'Invoiced',
}

export enum PICK_SLIP_STATUS {
  OPEN = 'Open',
  READY_TO_PICK = 'Ready_To_Pick',
  PAUSED = 'Paused',
  IN_PICKING = 'In_Picking',
  PICKED = 'Picked',
  COMPLETE = 'Complete',
}

export enum CONSIGNMENT_NOTE_STATUS {
  OPEN = 'Open',
  FINALISED = 'Finalised',
  APPROVED = 'Approved',
  IN_PROGRESS = 'InProgress',
  BOOKED = 'Booked',
  AWAITING_PICKUP = 'AwaitingPickup',
  IN_TRANSIT = 'InTransit',
  DELIVERED = 'Delivered',
  CANCELLED = 'Cancelled',
  FAILED = 'Failed',
  ERROR = 'Error',
}

export enum ConsignmentNoteInvoiceMode {
  AllSalesOrderLines = 'AllSalesOrderLines',
  PickedLinesOnly = 'PickedLinesOnly',
}

export enum DISCOUNT_MODE {
  PERCENT = 'Percent',
  DOLLAR = 'Dollar',
  NONE = 'None',
}

export enum SHIPPING_MODE {
  PERCENT = 'Percent',
  DOLLAR = 'Dollar',
}

export enum WorkflowStatus {
  Aborted = 'Aborted',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Disabled = 'Disabled',
  Failed = 'Failed',
  NotRunning = 'NotRunning',
  Running = 'Running',
  Scheduled = 'Scheduled',
  InProcess = 'InProcess',
  New = 'New',
  Workflow = 'Workflow',
}

export enum InvoicePaymentAllocationStatus {
  New = 'New',
  InProgress = 'InProgress',
  Complete = 'Complete',
  RolledBack = 'RolledBack',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Declined = 'Declined',
  Paid = 'Paid',
  Cleared = 'Cleared',
  AwaitingAuthorisation = 'AwaitingAuthorisation',
  Scheduled = 'Scheduled',
  Submitted = 'Submitted', // UI-only
  CancellationInProgress = 'CancellationInProgress', // UI-only
}

export enum DateFilterNames {
  DueLastMonth = 'DueLastMonth',
  DueThisMonth = 'DueThisMonth',
  DueNextMonth = 'DueNextMonth',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  OlderThenLastMonth = 'OlderThenLastMonth',
  HideSelected = 'HideSelected',
  ShowOnlySelected = 'ShowOnlySelected',
}

export enum DatTypesLabelName {
  CreditNote = 'Credit Note',
  Invoice = 'Invoice',
  Payment = 'Payment',
  InvoicePaymentAllocation = 'Allocation',
  VendorCreditNote = 'Vendor Credit Note',
  VendorInvoice = 'Vendor Invoice',
  VendorPayment = 'Vendor Payment',
  VendorInvoicePaymentAllocation = 'Vendor Allocation',
}

export const Regex = {
  /*
  What password regex enforces:-
   1) Password can contain any character at any place in string.
   2) It must contain one numeric and one non numeric character character.
 
   Valid Password:
   1) test%1
   2) @12345
   3) abcd12

   Invalid Password:
   1) abcde@
  */
  password:
    /(([A-Za-z]|[-{}[\]!@#$%^&*()_+=,.;:'"`~?\/\\])+[0-9]|[0-9]+([A-Za-z]|[-{}[\]!@#$%^&*()_+=,.;:'"`~?\/\\]))[A-Za-z0-9-{}[\]!@#$%^&*()_+=,.;:'"`~?\/\\]*/g,
};

export const SalesOrdersViewMode = {
  All: 'All',
  RequiresAttention: 'RequiresAttention',
  ReadyToPick: 'ReadyToPick',
  ReadyToPack: 'ReadyToPack',
  AwaitingCollection: 'AwaitingCollection',
};

export enum PurchaseInvoiceViewMode {
  All = 'All',
  RequiresAttention = 'RequiresAttention',
  ReadyToPost = 'ReadyToPost',
  Posted = 'Posted',
}

export enum PurchaseOrderDashboardViewMode {
  Requisitions = 'Requisitions',
  PurchaseOrders = 'PurchaseOrders',
  DeliveryReceipts = 'DeliveryReceipts',
}

export enum DeliveryMethods {
  Cash_and_Carry = 'Cash_and_Carry',
  Deliver = 'Deliver',
  Warehouse_Pickup = 'Warehouse_Pickup',
  Dropship = 'Dropship',
}

export enum CreditType {
  CreditNote = 'CREDIT_NOTE',
  Claim = 'CLAIM',
  Prepayment = 'Prepayment',
}
export enum PaymentMethods {
  Cash = 0,
  PayPal = 1,
  PaymentExpress = 2,
  GiftCard = 3,
  DebtorAccount = 4,
  CashSaleAccount = 5,
  Eftpos = 6,
  AppleiTunes = 7,
  GooglePlay = 8,
  Cheque = 9,
  Caplink = 10,
  CreditCard = 11,
  Paymark = 12,
  DirectDeposit = 13,
  Afterpay = 14,
  zipPay = 15,
  zipMoney = 16,
  EasyPay = 17,
  Certegy = 18,
  Telstra_Direct = 19,
  Telstra_Group = 20,
  Invoice = 21,
  DirectDebit = 22,
  AmericanExpress = 23,
  Square = 24,
  DinersClub = 25,
  UnionPay = 26,
  Mastercard = 27,
  Visa = 28,
  BankTransfer = 29,
  McTrackBPA_Card = 30,
  McTrackSPA_Card = 31,
  McTrackBPA_AtoA = 32,
  McTrackSPA_AtoA = 33,
  InvigoPayLater = 34,
}

export enum PaymentBatchStatusTypes {
  Open = 'Open',
  ReadyForApproval = 'ReadyForApproval',
  PaymentApproved = 'PaymentApproved',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  ProcessingDebit = 'ProcessingDebit',
  DebitSuccessful = 'DebitSuccessful',
  DebitDeclined = 'DebitDeclined',
  ProcessingCredit = 'ProcessingCredit',
  Incomplete = 'Incomplete',
  DebitPreApproved = 'DebitPreApproved',
}

export enum PaymentBatchStatusValues {
  Open = 0,
  ReadyForApproval = 1,
  PaymentApproved = 3,
  Cancelled = 5,
  InProgress = 6,
  ProcessingDebit = 8,
  DebitPreApproved = 9,
  DebitSuccessful = 10,
  DebitDeclined = 11,
  ProcessingCredit = 12,
  Incomplete = 13,
  Failed = 14,
  Completed = 15,
}

export enum LabelPaymentBatchStatusTypes {
  Open = 'Draft',
  ReadyForApproval = 'Awaiting Approval',
  PaymentApproved = 'Pending Payment',
  InProgress = 'In Progress',
  Failed = 'Failed', // wrong card details
  Cancelled = 'Cancelled',
  ProcessingDebit = 'In Progress',
  DebitPreApproved = 'Paid',
  DebitDeclined = 'Declined',
  DebitSuccessful = 'Paid',
  ProcessingCredit = 'Paid',
  Incomplete = 'Paid',
  Completed = 'Cleared',
}

export enum LabelPaymentBatchStatusColors {
  Open = '#444444',
  ReadyForApproval = '#C68A19',
  PaymentApproved = '#8476d5',
  InProgress = '#C68A19',
  Failed = '#C55D44',
  Cancelled = '#C55D44',
  ProcessingDebit = '#C68A19',
  DebitPreApproved = '#3D9F78',
  DebitDeclined = '#C55D44',
  DebitSuccessful = '#3D9F78',
  ProcessingCredit = '#3D9F78',
  Incomplete = '#3D9F78',
  Completed = '#3D9F78',
}

export enum PaymentBatchCrDrStatusTypes {
  Open = 'Open',
  InProgress = 'InProgress',
  Paid = 'Paid',
  Failed = 'Failed',
}

export enum LabelPaymentBatchCrDrStatusTypes {
  Open = 'Not Paid',
  InProgress = 'Processing',
  Paid = 'Paid',
  Failed = 'Failed',
  Cleared = 'Paid',
}

export enum PaymentBatchPermissionType {
  CreateBatch = 'CreateBatch',
  ApproveBatch = 'ApproveBatch',
}

export enum PaymentBatchPermissionState {
  Allowed = 'Allowed',
  NotAllowed = 'NotAllowed',
}

export const ConversationShowFor = [
  RequestedList.Overdue,
  RequestedList.Due,
  RequestedList.Search,
  RequestedList.Payable,
];

export enum PaymentWidgetScope {
  PIBL = 'PIBL',
  ACCOUNT_PAYABLE = 'ACCOUNT_PAYABLE',
  ADD_CREDIT_CARD = 'ADD_CREDIT_CARD',
  ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT',
  CONFIGURE_PAYMENT_OPTIONS = 'CONFIGURE_PAYMENT_OPTIONS',
  VERIFY_PAYMENT_METHOD = 'VERIFY_PAYMENT_METHOD',
  AUHTORISE_PAYMENT = 'AUHTORISE_PAYMENT',
  PSBL = 'PSBL',
  PREPAYMENT = 'PREPAYMENT',
  SPENDA_FINANCE = 'SPENDA_FINANCE',
  ADD_AIRPLUS = 'ADD_AIRPLUS',
}

export enum ConversationStatus {
  Open = 'Open',
  Closed = 'Closed',
}

export const ConvoUploadSupportedFileTypes = [
  '.m4v',
  '.mkv',
  '.mov',
  '.mp4',
  '.mpg',
  '.mpeg',
  '.bmp',
  '.gif',
  '.jpeg',
  '.jpg',
  '.png',
  '.xls',
  '.xlsm',
  '.xlsx ',
  '.doc',
  '.docx',
  '.pdf',
];

export enum ConvoMessageType {
  Message = 1,
  Attachment = 2,
}

export enum ButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  DESTRUCTIVE = 'DESTRUCTIVE',
  FRAMELESSDESTRUCTIVE = 'FRAMELESSDESTRUCTIVE',
}

export enum AlertDatType {
  AccountsReceivableResolvedConversation = 212,
  StatementsPDF = 701,
}

export enum AlertType {
  MESSAGING = 'Messaging',
  DOCUMENT = 'Document',
}

export enum ConversationType {
  DOCUMENT = 'DOCUMENT',
  CUSTOMER = 'CUSTOMER',
}

export enum StartConvoType {
  ICON = 'ICON',
  BUTTON = 'BUTTON',
}

export enum PSBLBatchAction {
  ADD = 1,
  REMOVE = 2,
}

export enum PaymentTabs {
  PAYMENT_HISTORY = '/payment-history',
  BILLS = '/bills',
}

export enum UserRoleTypes {
  ADMIN = 'Administrator',
  SUPPORT = 'Support',
  PRIMARY = 'Synkd Manager',
  STANDARD = 'Synkd Staff',
}

export enum PaymentServiceType {
  BANK_ACCOUNT = 'BankAccount',
  CREDIT_CARD = 'CreditCard',
  SPENDA_FINANCE = 'MarketplaceFacility',
}

export enum AuthorisationRequestMode {
  PARALLEL = 'Parallel',
  SEQUENTIAL = 'Sequential',
}

export enum PurchaseInvoicePriceEntryType {
  TaxInclusive = 'TaxInclusive',
  TaxExclusive = 'TaxExclusive',
}

export enum ScheduledTaskRecurrenceType {
  RunEvery = 0,
  TimeOfDay = 1,
}

export enum AuthorisationStatus {
  NotRequired = 'NotRequired',
  InProgress = 'InProgress',
  AwaitingCode = 'AwaitingCode',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Complete = 'Complete',
  Failed = 'Failed',
}

export enum ARCustomerStatementOperation {
  NEW = '1',
  UPDATE = '2',
  OPEN = '3',
  CANCEL = '4',
  FINALISE = '5',
}

export enum ARCustomerStatementResponseStatus {
  OPEN = 'Open',
  CANCEL = 'Cancelled',
  FINALISE = 'Finalised',
}

export enum ARStatementDeltasActions {
  ADD = '1',
  REMOVE = '2',
}

export enum ARStatementPeriodDefaultOptions {
  ALL = 'ALL',
  END_OF_LAST_MONTH = 'End Of Last Month',
}

export enum PurchaseInvoiceStatusTypes {
  Overdue = 'Overdue',
  Unfinalised = 'Unfinalised',
  Finalised = 'Finalised',
  Paid = 'Paid',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
}

export enum PaymentAuthorisationPurpose {
  AuthoriseRules = 'PaymentAccount',
  AuthorisePayment = 'Payment',
}

export enum RequisitionType {
  Purchase = 'Purchase',
  Stock_Transfer = 'Stock_Transfer',
}

export enum RequisitionStatusType {
  New = 'New',
  Pending = 'Pending',
  Approved = 'Approved',
  Processed = 'Processed',
}

export enum ARModals {
  CreateStatementModal = 'CreateStatementModal',
  IsOtherUserEditingStatement = 'IsOtherUserEditingStatement',
  PaymentAuthorityChangeAlertModal = 'PaymentAuthorityChangeAlertModal',
}

export const ARSelectableTransactions = [DatTypes.Invoice, DatTypes.CreditNote, DatTypes.Claim, DatTypes.Payment];

export enum ARAgeReceivableDateRange {
  DAYS_7_14_28,
  DAYS_30_60_90,
  WEEKS_1_2_4,
  MONTHS_1_2_3,
  MONTHS_2_4_6,
  MONTHS_3_6_9,
  BY_MONTH,
}

export enum QuoteDetailsValidDate {
  DAY_7 = '7 days',
  DAY_14 = '14 days',
  DAY_30 = '30 days',
  CUSTOMISE_DAYS = 'Customise days',
}

export enum SyncStatuses {
  Error = 'Error',
  Ignore = 'Ignore',
  Imported = 'Imported',
  Invalid = 'Invalid',
  Pending = 'Pending',
  Ready = 'Ready',
  Synced = 'Synced',
  Syncing = 'Syncing',
}

export enum InventoryType {
  Inventory = 'Inventory',
  Fee = 'Fee',
  Labour = 'Labour',
}

export enum DeliveryReceiptStatusTypes {
  In_Progress = 'In_Progress',
  Verified = 'Verified',
  // when delivery receipt has been processed by the SP and
  // stock has gone up in the receiving warehouse
  Complete = 'Complete',
  // set when all received lines in receipt have been billed
  // in a purchase invoice
  Billed = 'Billed',
}

export enum DeliveryReceiptLineStatusTypes {
  In_Progress = 'In_Progress',
  // line has been processed
  Complete = 'Complete',
}

export enum ARInvoicePaymentAllocationStatus {
  InProgress = 'InProgress',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Declined = 'Declined',
  Paid = 'Paid',
  Cleared = 'Cleared',
  AwaitingAuthorisation = 'AwaitingAuthorisation',
  Scheduled = 'Scheduled',
  CancellationInProgress = 'CancellationInProgress', // UI-Only
}

export enum ARPaymentMethodIconsStatus {
  Visa = 'Visa',
  Mastercard = 'Mastercard',
  AmericanExpress = 'American Express',
  CreditNotes = 'CreditNotes',
  BankTransfer = 'Bank Transfer',
  Invigo = 'Invigo Buy Now Pay Later',
  SpendaFinance = 'Spenda Finance',
  AirPlus = 'AirPlus',
  CapricornDSD = 'Capricorn DSD',
}

export enum APErrorCodes {
  InvalidBSB = 'PMT032',
  PostingSupplier = 'APT001',
  PostingSupplierName = 'APT002',
  InventoryCode = 'APT005',
  PotentialDuplicate = 'APT004',
  PurchasableInventory = 'APT006',
  MissingABN = 'APT007',
}

export enum ClaimReasons {
  Damaged = 'Damaged',
  Faulty = 'Faulty',
  NotSuitable = 'Not_Suitable',
  Lost = 'Lost',
  OrderErrorCustomer = 'Order_Error_Customer',
  OrderErrorSupplier = 'Order_Error_Supplier',
  ShortSupply = 'Short_Supply',
  PastExpiryDate = 'Past_Expiry_Date',
  Overcharged = 'Overcharged',
  Other = 'Other',
}

export enum ClaimActions {
  Credit = 'Credit',
  // Exchange = 'Exchange',
  // Resupply = 'Resupply',
  // Refund = 'Refund',
}

export enum ClaimsAndReturnsStatus {
  NoClaim = 'No_Claim',
  NotSent = 'Not_Sent',
  Sent = 'Sent', //sent to linked supplier
  Processing = 'Processing', //the supplier has added the claim but not approved/rejected
  Approved = 'Approved',
  Rejected = 'Rejected', //claim was added by supplier but rejected
  Ignored = 'Ignored', //not in use currently; claim was not added by supplier's tenant and ignored
  Finalised = 'Finalised', //claim has been accepted and actioned on in business system by supplier
  Sending = 'Sending', //claim is in the process of being sent as pdf
  SentAsPDF = 'Sent_As_PDF', //claim has been sent via pdf to unlinked supplier/prospect
  Cancelled = 'Cancelled', //claim has been cancelled by customer
  FinalisedRejected = 'Finalised_Rejected', //claim has been rejected and finalized (so in complete list)
  Open = 'Open', // claim request is created by the POS staff but hasn't been actioned yet
  RequiresApproval = 'Requires_Approval',
  Draft = 'Draft',
  Active = 'Active',
  Deleted = 'Deleted',
  Reviewed = 'Reviewed',
}

export enum ClaimAndReturnRowAction {
  Create = 0,
  Update = 1,
  Delete = 2,
}

export enum PaymentAuthenticationErrorMsg {
  CodeExpired = 'AUT014',
  InvalidCode = 'AUT008',
}

export enum ApprovalCodeDeliverytype {
  Mobile = 'Mobile',
  Email = 'Email',
}

export enum NotificationType {
  Unknown = 'Unknown',
  Payment = 'Payment',
  PaymentReminder = 'PaymentReminder',
  PaymentAccountSettings = 'PaymentAccountSettings',
  AuthorisationStatusRejected = 'AuthorisationStatusRejected',
  AuthorisationStatusApproved = 'AuthorisationStatusApproved',
}

export enum AttentionRequiredReason {
  NoAuthAttention = 'No authorisation rules have been set up yet',
}

export enum AttachmentTypeEnum {
  ClaimsAndReturnsClaimRequest,
  ClaimsAndReturnsClaim,
  MessagingConversion,
  TenantManagementLogos,
  Quotes,
  ServiceJob,
  ServiceJobCustomerSignature,
  TermsAndConditions,
}

export const DEFAULT_TAX_RATE = 10;

export enum TradingTermName {
  DaysAfterEOM = 'Days after EOM',
  DaysAfterInvoice = 'Days After Invoice Date',
}

export enum PaymentMethodErrors {
  NoFacilityRepaymentMethod = 'Please add your repayment method to start using this Lending account',
  InsufficientFunds = 'Insufficient funds available. Please remove some invoices from the batch',
}

export enum SpendaFinanceFacilityTransactionType {
  Drawdown = 'Drawdown',
  Receipt = 'Receipt',
  Overpayment = 'Overpayment',
}

export enum PaymentServiceName {
  MarketplaceProvider = 'Marketplace Provider',
}

export enum APOnboardingSteps {
  ConfigureFinancialAdaptor = 1,
  BusinessInformation = 2,
  AMLKYC = 3,
  SetupPaymentMethods = 4,
  ClearingAccount = 5,
  UserSettings = 6,
}

export enum PsblOnboardingSteps {
  ConfigureFinancialAdaptor = 1,
  BusinessInformation = 2,
  PostingConfigurations = 3,
}

export enum APOnboardingStepsName {
  ConfigureFinancialAdaptor = 'Accounting software',
  BusinessInformation = 'Business information',
  AMLKYC = 'AML/KYC',
  SetupPaymentMethods = 'Payment method',
  ClearingAccount = 'Clearing account',
  UserSettings = 'User settings',
}

// TODO: Change BusinessInformation enum to BoonOnboarding when we will remove lmiCommercialRelease92671 flag
export enum PsblOnboardingStepsName {
  ConfigureFinancialAdaptor = 'Configure financial adaptor',
  BusinessInformation = 'Business information',
  PostingConfigurations = 'Posting configurations',
}

export enum QuoteStatus {
  All = 'All',
  Draft = 'Open',
  Sent = 'Sent',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Expired = 'Expired',
  ServiceInitiated = 'ServiceInitiated',
  DepositReceived = 'DepositReceived',
}

export enum QuoteViewMode {
  All = 'All',
  Draft = 'Open',
  Sent = 'Sent',
  Approved = 'Approved',
  ExpiredRejected = 'ExpiredOrRejected',
}

export enum QuoteStatusLabel {
  All = 'All Status',
  Open = 'Draft',
  Sent = 'Sent',
  Approved = 'Approved',
  ExpiredRejected = 'Expired/Rejected',
  Rejected = 'Rejected',
  Expired = 'Expired',
  ServiceInitiated = 'Service Initiated',
  DepositReceived = 'Deposit Received',
}

export enum UserRoleName {
  Admin = 'Admin User',
  Standard = 'Standard User',
  Primary = 'Primary User',
}

export const AirPlusPortalURL = 'http://businesstravelportal.airplus.com/';

export const Countries = {
  NewZealand: 'NZ',
  Australia: 'Australia',
};

export enum PostingTypeOptions {
  PostAsASalesAdjustment = 'PostAsASalesAdjustment',
  PostAsARoundingAdjustment = 'PostAsARoundingAdjustment',
  PartiallyPayMyInvoice = 'PartiallyPayMyInvoice',
  DoNothing = 'DoNothing',
}
